import { Icon } from "@chakra-ui/icons";

export const PlayIcon = (props: any) => (
  <Icon viewBox="0 0 48 48" fill="none" {...props}>
    <path
      fill="currentColor"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.4509 9.07352C15.2573 8.34782 13.7564 8.30643 12.523 8.97435C11.2892 9.64012 10.5 10.9191 10.5 12.3343V35.6668C10.5 37.0825 11.2899 38.362 12.5246 39.0276C13.1125 39.345 13.7588 39.5 14.4 39.5C15.1089 39.5 15.821 39.3099 16.4503 38.9279L35.6509 27.2613C36.7869 26.5696 37.5 25.3426 37.5 24.0005C37.5 22.6584 36.788 21.4325 35.6524 20.7406L16.4509 9.07352Z"
    />
  </Icon>
);
