import { IPopulatedArtwork } from "@aatelier/data";
import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDebounce, useMount, useToggle } from "react-use";
import { Config } from "src/config";
import {
  CompletedQueueItem,
  UploadItemStatus,
  useUploadQueue,
} from "src/stores/upload_queue.store";
import { CachePolicies, useFetch } from "use-http";
import { arrayMove } from "src/utils/array_move";

export const useArtworkDetailPage = () => {
  const navigate = useNavigate();
  const { artworkId } = useParams<{ artworkId: string }>();

  const pushToUploadQueue = useUploadQueue((state) => state.add);
  const uploadQueue = useUploadQueue((state) =>
    state.queue.filter((queue) => queue.artworkId === artworkId)
  );
  const removeFromQueue = useUploadQueue((state) => state.remove);

  const { get } = useFetch<IPopulatedArtwork>(
    `${Config.app.apiHost}/v1/artworks`,
    { cachePolicy: CachePolicies.NO_CACHE }
  );

  const { del, patch } = useFetch<IPopulatedArtwork>(
    `${Config.app.apiHost}/v1/artworks/${artworkId}`
  );

  const { del: deleteMedia } = useFetch<IPopulatedArtwork>(
    `${Config.app.apiHost}/v1/artworks/${artworkId}/media`
  );

  const [artwork, $setArtwork] = React.useState<IPopulatedArtwork>(
    {} as IPopulatedArtwork
  );
  const [loading, $setLoading] = React.useState(true);
  const [deleting, $setDeleting] = React.useState(false);
  const [showDeletePrompt, toggleDeletePrompt] = useToggle(false);

  useMount(async () => {
    const data = await get(artworkId);
    if (data) {
      $setArtwork(data);
      $setLoading(false);
    }
  });

  useEffect(() => {
    if (!artwork._id) return;
    for (const item of uploadQueue) {
      if (
        item.status === UploadItemStatus.uploaded &&
        item.artworkId === artworkId
      ) {
        const newMedia = (item as CompletedQueueItem).data;
        $setArtwork({
          ...artwork,
          gallery: [...artwork.gallery, newMedia],
          media_ids: [...artwork.media_ids, newMedia._id!],
        });
        removeFromQueue(item.id);
      }
    }
  }, [uploadQueue]);

  const onSelectMedia = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files?.length) return;
    const asArray = Array.from(event.target.files);
    for (const file of asArray) {
      pushToUploadQueue({ file, artworkId: artworkId! });
    }
  };

  const onDeleteArtwork = async () => {
    $setDeleting(true);
    await del();
    navigate(-1);
  };

  const onDeleteMedia = async (mediaId: string) => {
    const galleryWithDeletedMedia = artwork.gallery.filter(
      (media) => media._id !== mediaId
    );
    const deletedMediaId = artwork.media_ids.filter((id) => id !== mediaId);
    $setArtwork({
      ...artwork,
      gallery: galleryWithDeletedMedia,
      media_ids: deletedMediaId,
    });
    await deleteMedia(mediaId);
  };

  const updateSoldStatus = async (sold: boolean) => {
    await patch({
      sold,
    });
    $setArtwork((artwork) => ({
      ...artwork,
      sold,
    }));
  };

  const [hasMoved, setHasMoved] = useState(false);

  const moveMediaOrder = useCallback(
    async (dragIndex: number, hoverIndex: number) => {
      setHasMoved(true);
      const move = (arr: any[]) => arrayMove(arr, dragIndex, hoverIndex);

      $setArtwork((artwork) => ({
        ...artwork,
        media_ids: move(artwork.media_ids),
        gallery: move(artwork.gallery),
      }));
    },
    []
  );

  useDebounce(
    async () => {
      if (!hasMoved) return;
      await patch({
        media_ids: artwork.media_ids,
      });
    },
    2000,
    [artwork.media_ids]
  );

  return {
    loading,
    artwork,
    deleting,
    uploadQueue,
    onDeleteMedia,
    onSelectMedia,
    moveMediaOrder,
    onDeleteArtwork,
    showDeletePrompt,
    toggleDeletePrompt,
    markSold: () => updateSoldStatus(true),
    markUnsold: () => updateSoldStatus(false),
  };
};
