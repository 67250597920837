import { Center, Spinner } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { PageViewMode } from "src/constants";
import { ArtworkForm } from "./components/ArtworkForm/ArtworkForm";
import { useArtworkDetail } from "./useArtworkDetail";

export const EditArtworkPage = () => {
  const { artworkId } = useParams<{ artworkId: string }>();
  const { loading, artwork } = useArtworkDetail({ artworkId });

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <ArtworkForm
      artistId={artwork.artist_id}
      artwork={artwork}
      mode={PageViewMode.edit}
    />
  );
};
