import styled from "@emotion/styled";

export const MediaUploadQueueStyled = {
  Wrapper: styled.div`
    align-items: center;
    background-size: cover;
    background-position: center;
    display: flex;
    height: 128px;
    justify-content: center;
    margin: 0 8px 8px 0;
    width: 128px;
  `,
};
