import { IPopulatedPurchase } from "@aatelier/data";
import { useState } from "react";
import { useMount } from "react-use";
import { Config } from "src/config";
import { CachePolicies, useFetch } from "use-http";

type Response = {
  data: IPopulatedPurchase[];
};

export const usePurchasesListPage = () => {
  const [purchases, setPurchases] = useState<IPopulatedPurchase[]>([]);
  const [loading, $setLoading] = useState(true);

  const { get } = useFetch<Response>(`${Config.app.apiHost}/v1/purchases`, {
    loading: true,
    cachePolicy: CachePolicies.NO_CACHE,
  });

  useMount(async () => {
    const data = await get();
    if (data?.data) {
      setPurchases(data.data);
      $setLoading(false);
    }
  });

  return {
    loading,
    purchases,
  };
};
