import { IExhibitionStatus, IPopulatedExhibition } from "@aatelier/data";
import { TriangleDownIcon } from "@chakra-ui/icons";
import {
  Badge,
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { Link, useNavigate } from "react-router-dom";

const statusToColor: { [key in IExhibitionStatus]: string } = {
  past: "yellow",
  future: "blue",
  current: "green",
};

type ExhibitionItemRowProps = {
  isDeleting: boolean;
  exhibition: IPopulatedExhibition;
  onPromptDelete: (exhibitionId: string) => void;
};

export const ExhibitionItemRow = ({
  isDeleting,
  exhibition,
  onPromptDelete,
}: ExhibitionItemRowProps) => {
  const navigate = useNavigate();

  return (
    <Tr key={exhibition._id}>
      <Td>
        <img
          style={{
            minWidth: 48,
            width: 48,
            height: 48,
            objectFit: "cover",
          }}
          src={exhibition.poster.thumbnails.small?.full_url}
          alt={exhibition.name}
        />
      </Td>
      <Td>
        <Link to={`/dashboard/exhibitions/${exhibition._id}`}>
          <Box>
            <Text as="b">{exhibition.name}</Text>
            <Text fontSize="sm">{exhibition.venue}</Text>
          </Box>
        </Link>
      </Td>
      <Td>
        <Box>
          <Text>{exhibition.event_date}</Text>
          <Badge colorScheme={statusToColor[exhibition.status]}>
            {exhibition.status}
          </Badge>
        </Box>
      </Td>
      <Td>
        {isDeleting ? (
          <Spinner color="red.500" />
        ) : (
          <Menu>
            <MenuButton
              size="sm"
              as={IconButton}
              aria-label="Actions"
              icon={<TriangleDownIcon />}
              variant="outline"
            />
            <MenuList>
              <MenuItem
                onClick={() =>
                  navigate(`/dashboard/exhibitions/${exhibition._id}/edit`)
                }
              >
                Edit
              </MenuItem>
              <MenuItem
                color="red"
                onClick={() => onPromptDelete(exhibition._id)}
              >
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Td>
    </Tr>
  );
};
