import { IPopulatedExhibition } from "@aatelier/data";
import React from "react";
import { useMount } from "react-use";
import { Config } from "src/config";
import { CachePolicies, useFetch } from "use-http";

export const useExhibitionDetail = (exhibitionId?: string) => {
  const { get } = useFetch<IPopulatedExhibition>(
    `${Config.app.apiHost}/v1/exhibitions/${exhibitionId}`,
    {
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );
  const [exhibition, $setExhibition] = React.useState(
    {} as IPopulatedExhibition
  );
  const [loading, $setLoading] = React.useState(true);

  useMount(async () => {
    if (!exhibitionId) {
      return $setLoading(false);
    }

    const data = await get();
    if (data) {
      $setExhibition(data);
      $setLoading(false);
    }
  });

  const onRemoveArtwork = (artworkId: string) => {
    $setExhibition({
      ...exhibition,
      artworks: exhibition.artworks.filter(
        (artwork) => artwork._id !== artworkId
      ),
    });
  };

  return {
    loading,
    exhibition,
    onRemoveArtwork,
  };
};
