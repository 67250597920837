import {
  IPaginatedArtworksMetadata,
  IPaginatedResult,
  IPopulatedArtwork,
} from "@aatelier/data";
import { useState } from "react";
import { useMount } from "react-use";
import { CachePolicies, useFetch } from "use-http";
import { Config } from "src/config";

export type PaginatedArtworksResponse = IPaginatedResult<
  IPopulatedArtwork,
  IPaginatedArtworksMetadata
>;

export const useArtworksPage = () => {
  const [pageData, setPageData] = useState({} as PaginatedArtworksResponse);
  const [artworks, setArtworks] = useState<IPopulatedArtwork[]>([]);
  const [idToDelete, setIdToDelete] = useState<string | null>();
  const [loading, $setLoading] = useState(true);

  const { get } = useFetch<PaginatedArtworksResponse>(
    `${Config.app.apiHost}/v1/artworks`,
    {
      loading: true,
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );

  const { delete: deleteRequest, loading: deleteLoading } =
    useFetch<PaginatedArtworksResponse>(`${Config.app.apiHost}/v1/artworks`, {
      cachePolicy: CachePolicies.NO_CACHE,
    });

  useMount(async () => {
    const data = await get();
    if (data?.data) {
      setPageData(data);
      setArtworks(data.data);
      $setLoading(false);
    }
  });

  const onDelete = async () => {
    await deleteRequest(idToDelete!);
    setArtworks(artworks.filter((artwork) => artwork._id !== idToDelete));
  };

  const fetchNextPage = async () => {
    const data = await get(`?page=${pageData.next_page}`);
    setPageData(data);
    setArtworks([...artworks, ...data.data]);
  };

  return {
    loading,
    artworks,
    setIdToDelete,
    deletingId: deleteLoading ? idToDelete : null,
    onDelete,
    fetchNextPage,
    hasNextPage: !!pageData.next_page,
  };
};
