import { IPopulatedMediaImage, IPopulatedMediaVideo } from "@aatelier/data";
import { uuid } from "@aatelier/utils";
import create from "zustand";

export enum UploadItemStatus {
  queued = "queued",
  uploaded = "uploaded",
  error = "error",
}

type NewUploadQueueItem = {
  artworkId: string;
  file: File;
};

export type UploadQueueItem = NewUploadQueueItem & {
  id: string;
  status: UploadItemStatus;
};

export type CompletedQueueItem = UploadQueueItem & {
  status: UploadItemStatus.uploaded;
  data: IPopulatedMediaImage | IPopulatedMediaVideo;
};

type State = {
  queue: (UploadQueueItem | CompletedQueueItem)[];
  add: (item: NewUploadQueueItem) => void;
  markAsCompleted: (queueId: string, data: CompletedQueueItem["data"]) => void;
  markAsFailed: (queueId: string) => void;
  remove: (queueId: string) => void;
};

export const useUploadQueue = create<State>((set) => ({
  queue: [],
  completedItems: [],
  add: (item: NewUploadQueueItem) =>
    set((state) => {
      const _item = item as UploadQueueItem;
      _item.id = uuid();
      _item.status = UploadItemStatus.queued;

      return {
        queue: [...state.queue, _item],
      };
    }),
  markAsCompleted: (queueId: string, data: CompletedQueueItem["data"]) =>
    set((state) => {
      return {
        queue: state.queue.map((queueItem) => {
          if (queueItem.id === queueId) {
            (queueItem as CompletedQueueItem).data = data;
            queueItem.status = UploadItemStatus.uploaded;
          }
          return queueItem;
        }),
      };
    }),
  markAsFailed: (queueId: string) =>
    set((state) => {
      return {
        queue: state.queue.map((queueItem) => {
          if (queueItem.id === queueId) {
            queueItem.status = UploadItemStatus.error;
          }
          return queueItem;
        }),
      };
    }),
  remove: (queueId: string) =>
    set((state) => {
      return {
        queue: state.queue.filter((q) => q.id !== queueId),
      };
    }),
}));
