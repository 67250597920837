import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  Spacer,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { ExhibitionFormProps } from "./ExhibitionForm.props";
import { useExhibitionForm } from "./ExhibitionForm.state";

export const ExhibitionForm = (props: ExhibitionFormProps) => {
  const { mode, exhibition } = props;

  const { errors, loading, onSubmit, register, freeEntrance, setFreeEntrance } =
    useExhibitionForm({
      mode,
      exhibition,
    });

  return (
    <div style={{ padding: "56px 20px 0" }}>
      <form onSubmit={onSubmit}>
        <VStack spacing={4} align="start" alignItems="stretch">
          <FormControl isInvalid={!!errors.poster_file}>
            <FormLabel>Poster</FormLabel>
            <InputGroup>
              <input
                type="file"
                accept="image/*"
                {...register("poster_file")}
              />
            </InputGroup>
            <FormErrorMessage>
              {errors.poster_file?.message as any}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.name}>
            <FormLabel>Name</FormLabel>
            <InputGroup>
              <Input placeholder="e.g Beachclub" {...register("name")} />
            </InputGroup>
            <FormErrorMessage>{errors.name?.message as any}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.description}>
            <FormLabel>Description</FormLabel>
            <Textarea
              placeholder="Lorem ipsum dolor sit amet"
              {...register("description")}
            />
            <FormErrorMessage>
              {errors.description?.message as any}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.venue}>
            <FormLabel>Venue</FormLabel>
            <InputGroup>
              <Input placeholder="e.g Jimbaran Hub" {...register("venue")} />
            </InputGroup>
            <FormErrorMessage>{errors.venue?.message as any}</FormErrorMessage>
          </FormControl>

          <HStack align="start">
            <FormControl isInvalid={!!errors.start_date}>
              <FormLabel>Start Date</FormLabel>
              <InputGroup>
                <Input
                  type="date"
                  placeholder="e.g 31/01/2022"
                  {...register("start_date")}
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.start_date?.message as any}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.end_date}>
              <FormLabel>End Date</FormLabel>
              <InputGroup>
                <Input
                  type="date"
                  placeholder="e.g 31/12/2022"
                  {...register("end_date")}
                />
              </InputGroup>
              <FormErrorMessage>
                {errors.end_date?.message as any}
              </FormErrorMessage>
            </FormControl>
          </HStack>

          <Box>
            <FormControl
              isDisabled={freeEntrance}
              isInvalid={!!errors.entrance_fee}
            >
              <FormLabel>Entrance Fee</FormLabel>
              <InputGroup>
                <InputLeftAddon color="gray.300">IDR</InputLeftAddon>
                <NumberInput>
                  <NumberInputField
                    placeholder="e.g 275,000"
                    {...register("entrance_fee")}
                  />
                </NumberInput>
              </InputGroup>
              <FormErrorMessage>
                {errors.entrance_fee?.message as any}
              </FormErrorMessage>
            </FormControl>
            <Checkbox mt={1} checked={freeEntrance} onChange={setFreeEntrance}>
              Free entrance
            </Checkbox>
          </Box>

          <HStack>
            <Spacer />
            <Button
              size="md"
              type="submit"
              variant="outline"
              colorScheme="blue"
              isLoading={loading}
            >
              Save
            </Button>
          </HStack>
        </VStack>
      </form>
    </div>
  );
};
