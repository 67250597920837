import * as yup from "yup";
import { ICreateExhibition } from "../../interfaces";

// helper for yup transform function
function emptyStringToNull(value: number, originalValue: any) {
  if (typeof originalValue === "string" && originalValue === "") {
    return null;
  }
  return value;
}

// const KNOWN_IMAGE_TYPES = ["image/jpeg", "image/png", "image/webp"];

export const CreateExhibitionRequestSchema: yup.SchemaOf<ICreateExhibition> =
  yup
    .object()
    .shape({
      name: yup.string().required(),
      description: yup.string().required(),
      venue: yup.string().required(),
      start_date: yup.date().required(),
      end_date: yup.date().min(yup.ref("start_date")).required(),
      entrance_fee: yup.number().min(1).transform(emptyStringToNull).nullable(),
      // poster_file: yup
      //   .mixed()
      //   .required("Must provide a poster")
      //   .test("fileSize", "File too large, must be less than 3mb", (value) => {
      //     const fiveMb = 3 * 1024 * 1024;
      //     return (value?.[0]?.size ?? 0) <= fiveMb;
      //   })
      //   .test(
      //     "type",
      //     "File type not supported, only jpeg/png/webp are supported",
      //     (value) => {
      //       return (
      //         value?.[0]?.type && KNOWN_IMAGE_TYPES.includes(value[0].type)
      //       );
      //     }
      //   ),
    })
    .required();
