import { IPopulatedMediaImage, SupportedMimeTypes } from "@aatelier/data";
import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Spacer,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link, useParams } from "react-router-dom";
import { SimpleAlert } from "../../../../../components";
import { useArtistDetail } from "../useArtistDetail";
import { ArtistDetailArtworksTable } from "./components/ArtistDetailArtworksTable";

export const ArtistDetailPage = () => {
  const { artistId } = useParams<{ artistId: string }>();
  const {
    artist,
    loading,
    deleting,
    onDeleteArtist,
    showDeletePrompt,
    toggleDeletePrompt,
  } = useArtistDetail(artistId);

  if (loading || !artist) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  const featuredArtwork = artist.featured_artwork;
  const featuredArtworkMedia = featuredArtwork?.gallery.find(
    (media) => media.mime_type === SupportedMimeTypes.image
  ) as IPopulatedMediaImage | null;

  return (
    <>
      <VStack align="start" p="25px" spacing={4}>
        <HStack w="100%">
          <Text fontSize="2xl">{artist.name}</Text>

          <Spacer />

          <Button
            ml={2}
            size="sm"
            colorScheme="red"
            isLoading={deleting}
            leftIcon={<DeleteIcon />}
            onClick={toggleDeletePrompt}
          >
            Delete
          </Button>

          <Link to={`/dashboard/artists/${artist._id}/edit`}>
            <Button size="sm" leftIcon={<EditIcon />}>
              Edit
            </Button>
          </Link>
        </HStack>

        {!featuredArtwork && (
          <Alert status="error">
            <AlertIcon />
            <Box>
              <AlertTitle>This artist has no featured artwork!</AlertTitle>
              <AlertDescription>
                This artist will not show up on the website. Assign a featured
                artwork to resolve this issue.
              </AlertDescription>
            </Box>
          </Alert>
        )}

        <Flex>
          {(artist.profile_picture && (
            <Box w={artist.profile_picture.width + "px"}>
              <Text fontSize="xs" as="b">
                Featured Artwork
              </Text>
              <img
                alt="Atists featured artworks"
                src={artist.profile_picture.full_url}
                height={artist.profile_picture.height}
              />
              <Text fontSize="sm" as="b" mt={4}>
                {artist.featured_artwork?.title}
              </Text>
            </Box>
          )) || (
            <Box>
              <Text fontSize="xs" as="b" color="red">
                Artwork image not available
              </Text>
            </Box>
          )}

          <Box flex={1} ml={2}>
            <Box>
              <Text fontSize="xs" as="b">
                Description
              </Text>
              <Text>{artist.description}</Text>
            </Box>

            <Box mt={4}>
              <p>
                Is featured on home page:{" "}
                <Text as="b">{artist.featured ? "Yes" : "No"}</Text>
              </p>
              {(artist.featured && <p>Ranking {artist.ranking ?? "none"}</p>) ||
                null}
            </Box>
          </Box>
        </Flex>

        <ArtistDetailArtworksTable
          artist={artist}
          featured={featuredArtwork}
          artworks={artist.artworks ?? []}
        />

        <SimpleAlert
          isOpen={showDeletePrompt}
          onClose={toggleDeletePrompt}
          title="Delete Artist?"
          body="Are you sure? This will delete all of the artists artworks."
          ctaLabel="Delete"
          onCtaClick={async () => {
            toggleDeletePrompt(false);
            await onDeleteArtist();
          }}
        />
      </VStack>
    </>
  );
};
