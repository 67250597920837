import { IPopulatedArtwork } from "./IArtwork";
import { ICustomer } from "./ICustomer";

export enum PurchaseStatus {
  created = "created",
  expired = "expired",
  paid = "paid",
}

export interface IPurchase {
  _id: string;
  order_id: string;
  external_order_id: string;
  status: PurchaseStatus;
  artwork_ids: string[];
  purchase_total: number;
  created_at: string;
  customer: ICustomer;
  paid_at?: string;
  notification_history: any[];
  payment_type?: string | null;
}

export interface IPurchaseVirtuals {
  artworks: IPopulatedArtwork[];
  purchase_total_locale: string;
}

export type IPopulatedPurchase = IPurchase & IPurchaseVirtuals;

exports.default = {};
