import { Center, Spinner } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { PageViewMode } from "src/constants";
import { ExhibitionForm } from "./components/ExhibitionForm/ExhibitionForm";
import { useExhibitionDetail } from "./useExhibitionDetail";

export const EditExhibitionPage = () => {
  const { exhibitionId } = useParams<{ exhibitionId: string }>();
  const { loading, exhibition } = useExhibitionDetail(exhibitionId);

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return <ExhibitionForm exhibition={exhibition} mode={PageViewMode.edit} />;
};
