import styled from "@emotion/styled";
import React from "react";
import {
  UploadItemStatus,
  useUploadQueue,
} from "src/stores/upload_queue.store";
import { MediaUploadQueue } from "./MediaUploadQueue/MediaUploadQueue";

type Props = {};

export const QueueProcessor = (props: React.PropsWithChildren<Props>) => {
  const { children } = props;
  const queue = useUploadQueue((state) => state.queue);
  const markAsCompleted = useUploadQueue((state) => state.markAsCompleted);

  const itemToProcess = queue.filter(
    (item) => item.status === UploadItemStatus.queued
  )[0];

  return (
    <>
      {children}

      <UploadQueueStyle.Wrapper>
        {itemToProcess && (
          <>
            <MediaUploadQueue
              key={itemToProcess.id}
              queueItem={itemToProcess}
              onUploadCompleted={markAsCompleted}
            />
            <p>
              {queue.filter((q) => q.status === UploadItemStatus.queued).length}{" "}
              remaining...
            </p>
          </>
        )}
      </UploadQueueStyle.Wrapper>
    </>
  );
};

const UploadQueueStyle = {
  Wrapper: styled.div`
    bottom: 64px;
    left: 32px;
    position: fixed;
  `,
};
