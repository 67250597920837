export interface IMediaFile {
  width: number;
  height: number;
  url: string;
}

export interface IMediaFileVirtuals {
  full_url: string;
}

export type IPopulatedMediaFile = IMediaFile & IMediaFileVirtuals;

export interface IMediaVideoFile extends IMediaFile {
  length: number;
}

export type IPopulatedMediaVideoFile = IMediaVideoFile & IMediaFileVirtuals;

export enum SupportedMimeTypes {
  image = "image/webp",
  video = "video/mp4",
}

interface IMediaBase {
  _id?: string;
  file_size: number;
  mime_type: SupportedMimeTypes;
  path: string;
  blurhash?: string;
  file: IMediaFile;
}

export interface IMediaImage<T extends IMediaFile> extends IMediaBase {
  file: T;
  mime_type: SupportedMimeTypes.image;
  thumbnails: {
    // width: 64
    small: T;
    // width: 256
    medium: T;
    // width: 512
    large: T;
    // width: 1080
    xLarge: T;
  };
}

export interface IMediaVideo<T extends IMediaVideoFile> extends IMediaBase {
  file: T;
  mime_type: SupportedMimeTypes.video;
}

export type IPopulatedMediaImage = IMediaImage<IPopulatedMediaFile>;
export type IPopulatedMediaVideo = IMediaVideo<IPopulatedMediaVideoFile>;

export type IMedia = IMediaImage<IMediaFile> | IMediaVideo<IMediaVideoFile>;

export type IPopulatedMedia = IPopulatedMediaImage | IPopulatedMediaVideo;

exports.default = {};
