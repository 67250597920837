import { IPopulatedArtwork } from "./IArtwork";
import { IPopulatedMediaImage } from "./IMedia";
import { IWithTimeStamps } from "./IWithTimeStamps";
import { IImage } from "./IImage";

export enum IExhibitionStatus {
  past = "past",
  current = "current",
  future = "future",
}

export interface ICreateExhibition {
  name: string;
  slug: string;
  description?: string;
  venue: string;
  start_date: string;
  end_date: string;
  entrance_fee?: string | null;
  poster_file?: any;
}

export interface IExhibition extends ICreateExhibition, IWithTimeStamps {
  _id: string;
  poster_media_id: string;
}

export interface IExhibitionVirtuals {
  date: string;
  image: IImage;
  status: IExhibitionStatus;
  poster: IPopulatedMediaImage;
  artworks: IPopulatedArtwork[];
  event_date: string;
}

export type IPopulatedExhibition = IExhibition & IExhibitionVirtuals;

exports.default = {};
