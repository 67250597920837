import { IPopulatedArtist } from "./IArtist";
import { IPopulatedArtwork } from "./IArtwork";
import { IPopulatedExhibition } from "./IExhibition";

export interface IWebsiteHomeData {
  artworks: IPopulatedArtwork[];
  artists: IPopulatedArtist[];
  exhibitions: IPopulatedExhibition[];
}

exports.default = {};
