import * as yup from "yup";
import { IArtwork } from "../../interfaces";

export const PostArtworkRequestSchema: yup.SchemaOf<IArtwork> = yup
  .object()
  .shape({
    artist_id: yup.string().required(),
    title: yup.string().required(),
    year: yup.number().required(),
    art_size: yup.string().required().label("Art size"),
    media: yup.string().required(),
    price: yup.number().required(),
    ranking: yup.number(),
    price_by_request: yup.boolean(),
    exhibition_ids: yup.array().of(yup.string()).label("Exhibition"),
    style_and_mood: yup
      .array()
      .of(yup.string())
      .min(1, "Please select at least 1 style")
      .required()
      .label("Style and mood"),
    room_size: yup
      .array()
      .of(yup.string())
      .min(1, "Please select at least 1 room size")
      .required()
      .label("Room size"),
  })
  .required();
