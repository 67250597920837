import { Box, Button, Spacer, Text, VStack } from "@chakra-ui/react";
import { Link, Outlet, Route, useLocation } from "react-router-dom";
import { useAuth } from "../../providers";
import { DashboardPageStyled } from "./DashboardPage.styled";
import { ArtistDetailPage } from "./pages/artists/ArtistDetailPage/ArtistDetailPage";
import { ArtistsListPage } from "./pages/artists/ArtistsListPage/ArtistsListPage";
import { CreateArtistPage } from "./pages/artists/CreateArtistPage";
import { EditArtistPage } from "./pages/artists/EditArtistPage";
import { ArtworkDetailPage } from "./pages/artworks/ArtworkDetailPage/ArtworkDetailPage";
import { ArtworksListPage } from "./pages/artworks/ArtworksList/ArtworksListPage";
import { CreateArtworkPage } from "./pages/artworks/CreateArtworkPage";
import { EditArtworkPage } from "./pages/artworks/EditArtworkPage";
import { CreateExhibitionPage } from "./pages/exhibitions/CreateExhibitionPage";
import { EditExhibitionPage } from "./pages/exhibitions/EditExhibitionPage";
import { ExhibitionDetailPage } from "./pages/exhibitions/ExhibitionDetailPage/ExhibitionDetailPage";
import { ExhibitionsListPage } from "./pages/exhibitions/ExhibitionsList/ExhibitionsListPage";
import { PurchaseDetailPage, PurchasesListPage } from "./pages/purchases";
import { QueueProcessor } from "./wrapper/QueueProcessor";

const ROUTES = [
  {
    label: "Artists",
    path: "/dashboard/artists",
  },
  {
    label: "Artworks",
    path: "/dashboard/artworks",
  },
  {
    label: "Exhibitions",
    path: "/dashboard/exhibitions",
  },
  {
    label: "Purchases",
    path: "/dashboard/purchases",
  },
];

export const DashboardPage = () => {
  const { logout, logoutLoading } = useAuth();
  const { pathname } = useLocation();

  const isActive = (path: string) => {
    return new RegExp(`${path}`).test(pathname);
  };

  return (
    <QueueProcessor>
      <DashboardPageStyled.Wrapper>
        <DashboardPageStyled.Sidebar>
          <VStack h="100%" align="start" w="100%" alignItems="stretch">
            <Text p={4} as="b" fontSize="lg" mt={6} mb={8}>
              Aatelier
            </Text>

            {ROUTES.map((route) => (
              <Link key={route.path} to={route.path}>
                <DashboardPageStyled.SidebarItem
                  $isActive={isActive(route.path)}
                >
                  {route.label}
                </DashboardPageStyled.SidebarItem>
              </Link>
            ))}

            <Spacer />
            <Box p={4} w="100%">
              <Button
                w="100%"
                variant="ghost"
                onClick={logout}
                colorScheme="red"
                isLoading={logoutLoading}
              >
                Logout
              </Button>
            </Box>
          </VStack>
        </DashboardPageStyled.Sidebar>

        <DashboardPageStyled.Content>
          <Outlet />
        </DashboardPageStyled.Content>
      </DashboardPageStyled.Wrapper>
    </QueueProcessor>
  );
};

DashboardPage.Routes = (
  <Route path="dashboard" element={<DashboardPage />}>
    {/* Artworks */}
    <Route path="artworks" element={<ArtworksListPage />} />
    <Route path="artworks/:artworkId/edit" element={<EditArtworkPage />} />
    <Route path="artworks/:artworkId" element={<ArtworkDetailPage />} />

    {/* Artists */}
    <Route path="artists" element={<ArtistsListPage />} />
    <Route path="artists/new" element={<CreateArtistPage />} />
    <Route path="artists/:artistId/edit" element={<EditArtistPage />} />
    <Route
      path="artists/:artistId/new-artwork"
      element={<CreateArtworkPage />}
    />
    <Route path="artists/:artistId" element={<ArtistDetailPage />} />

    {/* Exhibitions */}
    <Route path="exhibitions" element={<ExhibitionsListPage />} />
    <Route path="exhibitions/new" element={<CreateExhibitionPage />} />
    <Route
      path="exhibitions/:exhibitionId/edit"
      element={<EditExhibitionPage />}
    />
    <Route
      path="exhibitions/:exhibitionId"
      element={<ExhibitionDetailPage />}
    />

    {/* Purchases */}
    <Route path="purchases" element={<PurchasesListPage />} />
    <Route path="purchases/:purchaseId" element={<PurchaseDetailPage />} />
  </Route>
);
