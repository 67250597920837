import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  HStack,
  Spacer,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { SimpleAlert } from "src/components";
import { useArtistsListPage } from "./ArtistsListPage.state";
import { ArtistsListItem } from "./components/ArtistsListItem";

export const ArtistsListPage = () => {
  const { loading, artists, onDelete, setIdToDelete, deletingId } =
    useArtistsListPage();

  const { isOpen, onOpen, onClose } = useDisclosure();

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <HStack p={8}>
        <Text fontSize="2xl">Artists</Text>
        <Spacer />
        <Link to="/dashboard/artists/new">
          <Button size="sm" leftIcon={<AddIcon />}>
            Add Artist
          </Button>
        </Link>
      </HStack>
      <TableContainer>
        <Table>
          <TableCaption>Artists</TableCaption>
          <Thead>
            <Tr>
              <Th w="48px">IMG</Th>
              <Th w="100%">Name</Th>
              <Th isNumeric>Featured</Th>
              <Th w="48px" />
            </Tr>
          </Thead>
          <Tbody>
            {artists.map((artist) => (
              <ArtistsListItem
                key={artist._id}
                artist={artist}
                isDeleting={deletingId === artist._id}
                onDeletePrompt={() => {
                  setIdToDelete(artist._id);
                  onOpen();
                }}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <SimpleAlert
        isOpen={isOpen}
        onClose={onClose}
        title="Delete Artist?"
        body="Are you sure? This will delete all of the artists artworks."
        ctaLabel="Delete"
        onCtaClick={async () => {
          onClose();
          await onDelete();
        }}
      />
    </>
  );
};
