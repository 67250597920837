import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  NumberInput,
  NumberInputField,
  Spacer,
  Textarea,
  VStack,
} from "@chakra-ui/react";
import { ArtistFormProps } from "./ArtistForm.props";
import { useArtistForm } from "./ArtistForm.state";

export const ArtistForm = (props: ArtistFormProps) => {
  const { mode, artist } = props;

  const { errors, loading, isDirty, onSubmit, register, isFeatured } =
    useArtistForm({
      mode,
      artist,
    });

  return (
    <div style={{ padding: "56px 20px 0" }}>
      <form onSubmit={onSubmit}>
        <VStack spacing={4} align="start" alignItems="stretch">
          <FormControl isInvalid={!!errors.name}>
            <FormLabel>Name</FormLabel>
            <InputGroup>
              <Input placeholder="e.g John Doe" {...register("name")} />
            </InputGroup>
            <FormErrorMessage>{errors.name?.message as any}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.description}>
            <FormLabel>Description</FormLabel>
            <Textarea
              placeholder="e.g Was born in 1970"
              {...register("description")}
            />
            <FormErrorMessage>
              {errors.description?.message as any}
            </FormErrorMessage>
          </FormControl>

          <Box>
            <Checkbox {...register("featured")}>Feature on home page?</Checkbox>

            <FormControl isDisabled={!isFeatured} isInvalid={!!errors.ranking}>
              <FormLabel>Ranking</FormLabel>
              <InputGroup>
                <NumberInput>
                  <NumberInputField
                    placeholder="e.g 4"
                    {...register("ranking")}
                  />
                </NumberInput>
              </InputGroup>
              <FormErrorMessage>
                {errors.ranking?.message as any}
              </FormErrorMessage>
            </FormControl>
          </Box>

          <HStack>
            <Spacer />
            <Button
              size="md"
              type="submit"
              variant="outline"
              colorScheme="blue"
              disabled={!isDirty}
              isLoading={loading}
            >
              Save
            </Button>
          </HStack>
        </VStack>
      </form>
    </div>
  );
};
