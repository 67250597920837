import { IPopulatedArtist } from "@aatelier/data";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useMount, useToggle } from "react-use";
import { Config } from "src/config";
import { CachePolicies, useFetch } from "use-http";

export const useArtistDetail = (artistId?: string) => {
  const navigate = useNavigate();
  const { get, del } = useFetch<IPopulatedArtist>(
    `${Config.app.apiHost}/v1/artists/${artistId}`,
    {
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );
  const [artist, $setArtist] = React.useState({} as IPopulatedArtist);
  const [loading, $setLoading] = React.useState(true);
  const [deleting, $setDeleting] = React.useState(false);
  const [showDeletePrompt, toggleDeletePrompt] = useToggle(false);

  useMount(async () => {
    if (!artistId) {
      return $setLoading(false);
    }

    const data = await get();
    if (data) {
      $setArtist(data);
      $setLoading(false);
    }
  });

  const onDeleteArtist = async () => {
    $setDeleting(true);
    await del();
    navigate("/dashboard/artists", {
      replace: true,
    });
  };

  return {
    loading,
    artist,
    deleting,
    showDeletePrompt,
    toggleDeletePrompt,
    onDeleteArtist,
  };
};
