import {
  IPopulatedArtist,
  IPopulatedArtwork,
  IPopulatedMediaImage,
  SupportedMimeTypes,
} from "@aatelier/data";
import { TriangleDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { useFetch } from "use-http";
import { Config } from "../../../../../../config";

type ExhibitionArtworksTableProps = {
  artist: IPopulatedArtist;
  artworks: IPopulatedArtwork[];
  featured?: IPopulatedArtwork;
};

export const ArtistDetailArtworksTable = ({
  artist,
  artworks,
  featured,
}: ExhibitionArtworksTableProps) => {
  // TODO(@boris) - 11/10/22: move to state
  const { patch, loading, response } = useFetch(
    `${Config.app.apiHost}/v1/artists/${artist._id}`
  );

  return (
    <Box mt={12} w="100%">
      <HStack>
        <Text fontSize="xl">Artworks</Text>
        <Spacer />
        <Link to={`/dashboard/artists/${artist._id}/new-artwork`}>
          <Button size="sm">Add Artwork</Button>
        </Link>
      </HStack>

      <TableContainer mt={4}>
        <Table size="sm">
          <TableCaption>Artworks</TableCaption>
          <Thead>
            <Tr>
              <Th w="48px">IMG</Th>
              <Th w="100%">Title</Th>
              <Th>Price</Th>
              <Th w="48px" />
            </Tr>
          </Thead>
          <Tbody>
            {artworks.map((artwork) => {
              const thumbnail = (
                artwork.gallery.find(
                  (media) => media.mime_type === SupportedMimeTypes.image
                ) as IPopulatedMediaImage
              )?.thumbnails.small;
              return (
                <Tr key={artwork._id}>
                  <Td>
                    {(thumbnail && (
                      <img
                        style={{
                          minWidth: 48,
                          width: 48,
                          height: 48,
                          objectFit: "cover",
                        }}
                        src={thumbnail.full_url}
                        alt={artwork.title}
                      />
                    )) || (
                      <Center>
                        <Text color="red" fontSize="xs">
                          N/A
                        </Text>
                      </Center>
                    )}
                  </Td>

                  <Td>
                    <Link to={`/dashboard/artworks/${artwork._id}`}>
                      <Box>
                        <Text as="b">{artwork.title}</Text>
                        {featured?._id === artwork._id && (
                          <Text fontSize="sm">Featured</Text>
                        )}
                      </Box>
                    </Link>
                  </Td>

                  <Td>
                    <Box>
                      {(artwork.sold && (
                        <Box>
                          <Text decoration="line-through">
                            {artwork.price_locale}
                          </Text>
                          <Text as="b" fontSize="sm">
                            Sold
                          </Text>
                        </Box>
                      )) || <Text>{artwork.price_locale}</Text>}
                      {(artwork.monthly_rent_price && (
                        <Text fontSize="sm">
                          Rent: {artwork.monthly_rent_price_locale}
                        </Text>
                      )) ||
                        null}
                    </Box>
                  </Td>

                  <Td>
                    {loading ? (
                      <Spinner color="red.500" />
                    ) : (
                      <Menu>
                        <MenuButton
                          size="sm"
                          as={IconButton}
                          aria-label="Actions"
                          icon={<TriangleDownIcon />}
                          variant="outline"
                        />
                        <MenuList>
                          <MenuItem
                            // color="red"
                            onClick={async () => {
                              const data = await patch({
                                featured_artwork_id: artwork._id,
                              });
                              if (response.status === 200) {
                                window.location.reload();
                              }
                            }}
                          >
                            Use as artist profile
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    )}
                  </Td>
                </Tr>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
    </Box>
  );
};
