export function arrayMove<T>(
  arr: T[],
  old_index: number,
  new_index: number
): T[] {
  const copy = [...arr];
  if (new_index >= copy.length) {
    throw new Error(
      "Cant move item to an index greater than the arrays length"
    );
  }
  copy.splice(new_index, 0, copy.splice(old_index, 1)[0]);
  return copy; // for testing
}
