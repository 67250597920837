import { css } from "@emotion/react";
import styled from "@emotion/styled";

export const DashboardPageStyled = {
  Wrapper: styled.div`
    display: flex;
    height: 100vh;
    width: 100vw;
  `,

  Sidebar: styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    min-width: 196px;
    width: 196px;
  `,

  SidebarItem: styled.div<{ $isActive: boolean }>`
    border-left: 4px solid transparent;
    padding: 12px 16px;
    transition: all 110ms ease;

    :hover {
      background-color: rgba(0, 0, 0, 0.04);
    }

    ${(p) =>
      p.$isActive &&
      css`
        border-color: black;
        font-weight: bold;
      `}
  `,

  Content: styled.div`
    flex: 1 1 auto;
    overflow-y: scroll;
  `,
};
