import { IWithTimeStamps } from "./IWithTimeStamps";

export enum IUserRoles {
  customer = "customer",
  admin = "admin",
}

export interface IUser extends IWithTimeStamps {
  _id: string;
  full_name: string;
  email: string;
  role: IUserRoles;
  verification_otp?: string;
  verification_otp_sent_at?: string;
  verified_at?: string;
  reset_password_token?: string;
  reset_password_token_sent_at?: string;
  last_activity_at?: string;
}

export interface IUserVirtuals {}

export interface IPopulatedUser
  extends Omit<IUser, "hash" | "salt">,
    IUserVirtuals {}

exports.default = {};
