import {
  CreateExhibitionRequestSchema,
  ICreateExhibition,
  IPopulatedExhibition,
} from "@aatelier/data";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMount, useToggle } from "react-use";
import { Config } from "src/config";
import { CachePolicies, useFetch } from "use-http";
import { PageViewMode } from "src/constants";
import { ExhibitionFormProps } from "./ExhibitionForm.props";
import { format } from "date-fns";

const formatDate = (date = new Date().toISOString(), _format = "yyyy-MM-dd") =>
  format(new Date(date), _format);

export const useExhibitionForm = ({
  exhibition,
  mode,
}: ExhibitionFormProps) => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors },
  } = useForm<ICreateExhibition>({
    resolver: yupResolver(CreateExhibitionRequestSchema),
    defaultValues: {
      ...exhibition,
      start_date: formatDate(exhibition.start_date),
      end_date: formatDate(exhibition.end_date),
    },
  });
  const { post, patch, response, loading } = useFetch<IPopulatedExhibition>(
    `${Config.app.apiHost}/v1/exhibitions`,
    {
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );

  const [poster, _setPoster] = useState<File | null>(null);
  const [freeEntrance, setFreeEntrance] = useToggle(false);

  const onSubmit = handleSubmit(async (data) => {
    if (freeEntrance) {
      delete data.entrance_fee;
    }
    const formData = new FormData();

    for (const key in data) {
      const value = data[key as keyof ICreateExhibition]!;

      if (key === "poster_file") {
        formData.append("poster_file", value[0]);
      } else {
        formData.append(key, value);
      }
    }

    if (mode === PageViewMode.create) {
      await post(formData);
    } else {
      await patch(exhibition._id, data);
    }

    if (response.status === 200) {
      navigate("/dashboard/exhibitions", {
        replace: true,
      });
    }
  });

  useMount(() => {
    const subscription = watch((value, { name }) => {
      if (name === "poster_file") {
        _setPoster((value.poster_file as unknown as FileList)[0]);
      }
    });
    return () => subscription.unsubscribe();
  });

  return {
    poster,
    loading,
    onSubmit,
    register,
    errors,
    freeEntrance,
    setFreeEntrance,
  };
};
