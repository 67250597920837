import {
  Button,
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  InputGroup,
  InputLeftAddon,
  NumberInput,
  NumberInputField,
  Select,
  Spacer,
  VStack,
} from "@chakra-ui/react";
import { ArtworkFormProps } from "./ArtworkForm.props";
import { useArtworkForm } from "./ArtworkForm.state";

export const ArtworkForm = (props: ArtworkFormProps) => {
  const { mode, artwork, artistId } = props;

  const {
    errors,
    loading,
    onSubmit,
    register,
    isDirty,
    price,
    exhibitions,
    metadata,
    setValue,
  } = useArtworkForm({
    mode,
    artwork,
    artistId,
  });

  const parsedPrice = () => {
    try {
      const parsed = parseInt((price ?? "0").toString(), 10);
      return isNaN(parsed) ? "0" : parsed.toLocaleString();
    } catch (err) {
      return "";
    }
  };

  return (
    <div style={{ padding: "56px 20px 0" }}>
      <form onSubmit={onSubmit}>
        <VStack spacing={4} align="start" alignItems="stretch">
          <HStack>
            <Spacer />
            <Button
              size="md"
              type="submit"
              variant="outline"
              colorScheme="blue"
              disabled={!isDirty}
              isLoading={loading}
            >
              Save
            </Button>
          </HStack>

          <FormControl isInvalid={!!errors.ranking}>
            <FormLabel>Ranking</FormLabel>
            <Input placeholder="e.g 1" {...register("ranking")} />
            <FormErrorMessage>
              {errors.ranking?.message as any}
            </FormErrorMessage>
          </FormControl>

          <HStack>
            <FormControl>
              <FormLabel>Exhibition</FormLabel>
              <Select
                placeholder="Exhibition"
                onChange={(val) => {
                  if (!!val.target.value) {
                    setValue("exhibition_ids", [val.target.value], {
                      shouldDirty: true,
                    });
                  } else {
                    setValue("exhibition_ids", [], { shouldDirty: true });
                  }
                }}
              >
                {exhibitions.map((ex) => (
                  <option key={ex._id} value={ex._id}>
                    {ex.name}
                  </option>
                ))}
              </Select>
            </FormControl>

            <FormControl isInvalid={!!errors.title}>
              <FormLabel>Title</FormLabel>
              <Input
                placeholder="e.g Sunset in the North"
                {...register("title")}
              />
              <FormErrorMessage>
                {errors.title?.message as any}
              </FormErrorMessage>
            </FormControl>
          </HStack>

          <FormControl isInvalid={!!errors.year}>
            <FormLabel>Year</FormLabel>
            <Input placeholder="e.g 2014" {...register("year")} />
            <FormErrorMessage>{errors.year?.message as any}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.art_size}>
            <FormLabel>Art Size</FormLabel>
            <Input placeholder="e.g 120 x 190cm" {...register("art_size")} />
            <FormErrorMessage>
              {errors.art_size?.message as any}
            </FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.media}>
            <FormLabel>Media</FormLabel>
            <Input placeholder="e.g Acrylic on canvas" {...register("media")} />
            <FormErrorMessage>{errors.media?.message as any}</FormErrorMessage>
          </FormControl>

          <FormControl isInvalid={!!errors.price}>
            <FormLabel>Price</FormLabel>
            <InputGroup>
              <InputLeftAddon children="IDR" color="gray.300" />
              <NumberInput>
                <NumberInputField
                  placeholder="e.g 4,175,000"
                  {...register("price")}
                />
              </NumberInput>
            </InputGroup>
            <FormErrorMessage>{errors.price?.message as any}</FormErrorMessage>
            <small>IDR {parsedPrice()}</small>
          </FormControl>

          <Checkbox {...register("price_by_request")}>
            Price by request?
          </Checkbox>

          <HStack align="start">
            <FormControl isInvalid={!!errors.room_size}>
              <FormLabel>Room Size</FormLabel>
              {metadata.room_size.map((rs) => (
                <div key={rs}>
                  <Checkbox value={rs} {...register("room_size")}>
                    {rs}
                  </Checkbox>
                </div>
              ))}
              <FormErrorMessage>
                {errors.room_size?.message as any}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={!!errors.style_and_mood}>
              <FormLabel>Style and Mood</FormLabel>
              {metadata.style_and_mood.map((sm) => (
                <div key={sm}>
                  <Checkbox value={sm} {...register("style_and_mood")}>
                    {sm}
                  </Checkbox>
                </div>
              ))}
              <FormErrorMessage>
                {errors.style_and_mood?.message as any}
              </FormErrorMessage>
            </FormControl>
          </HStack>

          <HStack>
            <Spacer />
            <Button
              size="md"
              type="submit"
              variant="outline"
              colorScheme="blue"
              disabled={!isDirty}
              isLoading={loading}
            >
              Save
            </Button>
          </HStack>
        </VStack>
      </form>
    </div>
  );
};
