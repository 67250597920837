import { IPopulatedArtist } from "@aatelier/data";
import { useState } from "react";
import { useMount } from "react-use";
import { Config } from "src/config";
import { CachePolicies, useFetch } from "use-http";

type Response = {
  data: IPopulatedArtist[];
};

export const useArtistsListPage = () => {
  const [artists, setArtists] = useState<IPopulatedArtist[]>([]);
  const [idToDelete, setIdToDelete] = useState<string | null>();
  const [loading, $setLoading] = useState(true);

  const { get } = useFetch<Response>(`${Config.app.apiHost}/v1/artists`, {
    loading: true,
    cachePolicy: CachePolicies.NO_CACHE,
  });

  const { delete: deleteRequest, loading: deleteLoading } = useFetch<Response>(
    `${Config.app.apiHost}/v1/artists`,
    {
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );

  useMount(async () => {
    const data = await get();
    if (data?.data) {
      setArtists(data.data);
      $setLoading(false);
    }
  });

  const onDelete = async () => {
    await deleteRequest(idToDelete!);
    setArtists(artists.filter((artist) => artist._id !== idToDelete));
  };

  return {
    loading,
    artists,
    deletingId: deleteLoading ? idToDelete : null,
    setIdToDelete,
    onDelete,
  };
};
