import {
  IArtwork,
  IExhibition,
  IPopulatedArtwork,
  PostArtworkRequestSchema,
} from "@aatelier/data";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useMount } from "react-use";
import { Config } from "src/config";
import { PageViewMode } from "src/constants";
import { CachePolicies, useFetch } from "use-http";
import { ArtworkFormProps } from "./ArtworkForm.props";

type IMetadata = {
  room_size: string[];
  style_and_mood: string[];
};

export const useArtworkForm = ({
  artwork,
  mode,
  artistId,
}: ArtworkFormProps) => {
  const navigate = useNavigate();

  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, isDirty, dirtyFields },
    setValue,
  } = useForm<IArtwork>({
    resolver: yupResolver(PostArtworkRequestSchema),
    defaultValues: {
      ...artwork,
      artist_id: artistId,
      room_size: artwork.room_size ?? [],
      style_and_mood: artwork.style_and_mood ?? [],
    },
  });
  const { post, patch, loading } = useFetch<IPopulatedArtwork>(
    `${Config.app.apiHost}/v1/artworks`,
    {
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );

  const [metadata, $setMetadata] = useState<IMetadata>({
    room_size: [],
    style_and_mood: [],
  });
  const { get: getMetadata } = useFetch<IMetadata>(
    `${Config.app.apiHost}/v1/artworks/metadata`
  );

  const [exhibitions, $setExhibitions] = useState<IExhibition[]>([]);
  const { get: getExhibitions } = useFetch<{ data: IExhibition[] }>(
    `${Config.app.apiHost}/v1/exhibitions`
  );

  useMount(async () => {
    const data = await getMetadata();
    if (data) {
      $setMetadata(data);
    }
    const exs = await getExhibitions();
    if (exs?.data) {
      $setExhibitions(exs.data);
    }
  });

  const createArtworkRequest = (data: Partial<IArtwork>) => {
    data.artist_id = artistId;
    return post(data);
  };

  const updateArtworkRequest = (data: Partial<IArtwork>) => {
    const changedValues = (
      Object.keys(dirtyFields) as (keyof IArtwork)[]
    ).reduce(
      (all, key) => ({
        ...all,
        [key]: data[key],
      }),
      {}
    );
    return patch(artwork._id, changedValues);
  };

  const onSubmit = handleSubmit(async (data) => {
    let _artwork;
    if (mode === PageViewMode.create) {
      _artwork = await createArtworkRequest(data);
    } else {
      _artwork = await updateArtworkRequest(data);
    }

    if (_artwork?._id) {
      navigate(`/dashboard/artworks/${_artwork._id}`, {
        replace: true,
      });
    }
  });

  return {
    loading,
    metadata,
    exhibitions,
    setValue,
    onSubmit,
    register,
    errors,
    isDirty,
    price: watch("price"),
  };
};
