import * as yup from "yup";
import { ICreateArtist } from "../../interfaces";

export const CreateArtistRequestSchema: yup.SchemaOf<ICreateArtist> = yup
  .object()
  .shape({
    name: yup.string().required(),
    description: yup.string().required(),
    featured: yup.boolean().required(),
    ranking: yup
      .number()
      .when("featured", {
        is: true,
        then: yup.number().min(1),
      })
      .when("featured", {
        is: false,
        then: yup.number().min(0).nullable(),
      }),
  })
  .required();
