export class NumberUtils {
  static safeParse(value: string, fallback: number): number {
    try {
      const parsed = parseInt(value, 10);
      if (isNaN(parsed)) return fallback;
      return parsed;
    } catch (err) {
      return fallback;
    }
  }
}
