import { SupportedMimeTypes } from "@aatelier/data";
import { match } from "ts-pattern";
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";
import {
  Badge,
  Box,
  Button,
  Center,
  Divider,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { PlayIcon, SimpleAlert } from "src/components";
import { UploadItemStatus } from "../../../../../stores/upload_queue.store";
import { useArtworkDetailPage } from "./ArtworkDetailPage.state";
import { ArtworkDetailPageStyled } from "./ArtworkDetailPage.styled";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ArtworkDetailMediaItem } from "./components/MediaItem";

export const ArtworkDetailPage = () => {
  const {
    artwork,
    loading,
    deleting,
    markSold,
    markUnsold,
    uploadQueue,
    onDeleteMedia,
    onSelectMedia,
    moveMediaOrder,
    onDeleteArtwork,
    showDeletePrompt,
    toggleDeletePrompt,
  } = useArtworkDetailPage();

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <>
      <VStack align="start" alignItems="stretch" p="25px" spacing={4}>
        <HStack w="100%">
          <Box>
            <Text fontSize="2xl">{artwork.title}</Text>
            <Flex alignItems="center">
              <Text fontSize="sm" mr={2}>
                by
              </Text>
              <Link to={`/dashboard/artists/${artwork.artist._id}`}>
                <Text fontSize="sm" as="b">
                  {artwork.artist.name}
                </Text>
              </Link>
            </Flex>
          </Box>

          <Spacer />
          <Link to={`/dashboard/artworks/${artwork._id}/edit`}>
            <Button size="sm" leftIcon={<EditIcon />}>
              Edit
            </Button>
          </Link>
        </HStack>

        <Box mt={8}>
          <DndProvider backend={HTML5Backend}>
            <Flex flexWrap="wrap">
              {artwork.gallery.map((media, index) => {
                return (
                  <ArtworkDetailMediaItem
                    key={media._id}
                    media={media}
                    index={index}
                    moveMedia={moveMediaOrder}
                    showDeleteButton={artwork.gallery.length > 1}
                    onDelete={() => onDeleteMedia(media._id!)}
                  />
                );
              })}
            </Flex>
          </DndProvider>

          <Flex>
            {uploadQueue.map((queue) => (
              <ArtworkDetailPageStyled.QueuePreview
                key={queue.id}
                style={{
                  backgroundImage: `url(${URL.createObjectURL(queue.file)})`,
                }}
              >
                <ArtworkDetailPageStyled.QueuePreviewLabel>
                  {match(queue.status)
                    .with(UploadItemStatus.queued, () => "Uploading...")
                    .with(UploadItemStatus.uploaded, () => "Uploaded")
                    .with(UploadItemStatus.error, () => "Failed")
                    .exhaustive()}
                </ArtworkDetailPageStyled.QueuePreviewLabel>
              </ArtworkDetailPageStyled.QueuePreview>
            ))}

            <ArtworkDetailPageStyled.UploadButton>
              <ArtworkDetailPageStyled.UploadInput
                multiple
                type="file"
                accept="image/*,video/mp4"
                onChange={onSelectMedia}
              />
              Add media
            </ArtworkDetailPageStyled.UploadButton>
          </Flex>
        </Box>

        <Divider />

        {/*<Box w={featuredArtworkMedia.thumbnails.medium?.width + "px"}>
          <Text fontSize="lg">Featured Artwork</Text>
          <img
            alt="Atists featured artworks"
            src={featuredArtworkMedia.thumbnails.medium?.full_url}
            height={featuredArtworkMedia.thumbnails.medium?.height}
          />
          <Text fontSize="sm" as="b" mt={4}>
            {artist.featured_artwork.title}
          </Text>
        </Box>*/}

        <VStack spacing={4} align="start" w="100%">
          <Flex w="100%">
            <Box flex={1}>
              <Text fontSize="xs">Artist</Text>
              <Link to={`/dashboard/artists/${artwork.artist._id}`}>
                <Text as="b">{artwork.artist.name}</Text>
              </Link>
            </Box>

            <Box flex={1}>
              <Text fontSize="xs">Sort Ranking</Text>
              <Text as="b">{artwork.ranking ?? "none"}</Text>
            </Box>
          </Flex>

          <Divider />

          <Flex w="100%">
            <Box flex={1}>
              <HStack>
                <Box>
                  <Text fontSize="xs">Price</Text>
                  {(artwork.sold && (
                    <Flex alignItems="end">
                      <Text decoration="line-through">
                        {artwork.price_locale}
                      </Text>
                      <Text as="b" fontSize="sm" ml={2}>
                        Sold
                      </Text>
                    </Flex>
                  )) || <Text>{artwork.price_locale}</Text>}
                  {artwork.price_by_request ? (
                    <Text fontWeight="bold" fontSize="xs">
                      Price by Request
                    </Text>
                  ) : null}
                </Box>
                <Spacer />
                <Box p={2}>
                  {artwork.sold ? (
                    <Button size="sm" onClick={markUnsold}>
                      Mark as available
                    </Button>
                  ) : (
                    <Button size="sm" colorScheme="red" onClick={markSold}>
                      Mark as sold
                    </Button>
                  )}
                </Box>
              </HStack>
            </Box>

            <Box flex={1}>
              <Text fontSize="xs">Rent Price</Text>
              {(artwork.monthly_rent_price && (
                <Flex alignItems="end">
                  <Text>{artwork.monthly_rent_price_locale}</Text>
                  <Text fontSize="xs">/month</Text>
                </Flex>
              )) || <Text>Not available</Text>}
            </Box>
          </Flex>

          <Divider />

          <Flex w="100%">
            <Box flex={1}>
              <Text fontSize="xs">Year</Text>
              <Text>{artwork.year}</Text>
            </Box>

            <Box flex={1}>
              <Text fontSize="xs">Medium</Text>
              <Text>{artwork.media}</Text>
            </Box>
          </Flex>

          <Flex w="100%">
            <Box flex={1}>
              <Text fontSize="xs">Art Size</Text>
              <Text>{artwork.art_size}</Text>
            </Box>

            <Box flex={1}>
              <Text fontSize="xs">Room Size</Text>
              <Flex>
                {artwork.room_size.map((rs) => (
                  <Badge key={rs} mr={2}>
                    {rs}
                  </Badge>
                ))}
              </Flex>
            </Box>
          </Flex>

          <Flex w="100%">
            <Box flex={1}>
              <Text fontSize="xs">Print Available?</Text>
              {artwork.print_available ? (
                <Badge colorScheme="blue">Yes</Badge>
              ) : (
                <Badge colorScheme="red">No</Badge>
              )}
            </Box>

            <Box flex={1}>
              <Text fontSize="xs">Style and mood</Text>
              <Flex>
                {artwork.style_and_mood.map((sm) => (
                  <Badge key={sm} mr={2}>
                    {sm}
                  </Badge>
                ))}
              </Flex>
            </Box>
          </Flex>
        </VStack>

        <Divider />

        <HStack w="100%" mt={8}>
          <Spacer />
          {/*{artwork.sold ? (*/}
          {/*  <Button*/}
          {/*    size="sm"*/}
          {/*    variant="outline"*/}
          {/*    colorScheme="blue"*/}
          {/*    leftIcon={<UnlockIcon />}*/}
          {/*  >*/}
          {/*    Mark unsold*/}
          {/*  </Button>*/}
          {/*) : (*/}
          {/*  <Button*/}
          {/*    size="sm"*/}
          {/*    variant="outline"*/}
          {/*    colorScheme="red"*/}
          {/*    leftIcon={<NotAllowedIcon />}*/}
          {/*  >*/}
          {/*    Mark as sold*/}
          {/*  </Button>*/}
          {/*)}*/}
          <Button
            ml={2}
            size="sm"
            variant="outline"
            colorScheme="red"
            isLoading={deleting}
            leftIcon={<DeleteIcon />}
            onClick={toggleDeletePrompt}
          >
            Delete
          </Button>
        </HStack>
      </VStack>

      <SimpleAlert
        isOpen={showDeletePrompt}
        onClose={toggleDeletePrompt}
        title="Delete Artwork?"
        body="Are you sure? This will delete this artwork and its media"
        ctaLabel="Delete"
        onCtaClick={async () => {
          toggleDeletePrompt(false);
          await onDeleteArtwork();
        }}
      />
    </>
  );
};
