import { IPopulatedPurchase } from "@aatelier/data";
import React from "react";
import { useMount } from "react-use";
import { Config } from "src/config";
import { useFetch } from "use-http";

export const usePurchaseDetail = (purchaseId?: string) => {
  const { get } = useFetch<IPopulatedPurchase>(
    `${Config.app.apiHost}/v1/purchases/${purchaseId}`
  );
  const [purchase, $setPurchase] = React.useState({} as IPopulatedPurchase);
  const [loading, $setLoading] = React.useState(true);

  useMount(async () => {
    if (!purchaseId) {
      return $setLoading(false);
    }

    const data = await get();
    if (data) {
      $setPurchase(data);
      $setLoading(false);
    }
  });

  return {
    loading,
    purchase,
  };
};
