import { IPopulatedArtist } from "./IArtist";
import { IImage } from "./IImage";
import { IPopulatedMediaImage, IPopulatedMediaVideo } from "./IMedia";
import { IVideo } from "./IVideo";
import { IWithTimeStamps } from "./IWithTimeStamps";

export type IGalleryMediaType = "image" | "video";

export type IGalleryItem =
  | {
      type: "image";
      media: IImage;
    }
  | {
      type: "video";
      media: IVideo;
    };

export type IGallery = [
  {
    type: "image";
    media: IImage;
  },
  {
    type: "video";
    media: IVideo;
  }?,
  ...{
    type: "image";
    media: IImage;
  }[]
];

// as stored in DB
export interface IArtwork extends IWithTimeStamps {
  _id: string;
  title: string;
  slug: string;
  artist_id: string;
  media_ids: string[];
  price: number;
  price_by_request?: boolean;
  monthly_rent_price: number;
  sold: boolean;
  on_rent: boolean;
  featured: boolean;
  print_available: boolean;
  style_and_mood: string[];
  art_size: string;
  room_size: string[];
  media: string;
  year: string;
  exhibition_ids: string[];
  // for home page placement, higher is at top
  ranking: number;
}

export interface IArtworkVirtuals {
  price_locale: string;
  monthly_rent_price_locale: string;
  artist: IPopulatedArtist;
  match_rating: number;
  match_groups: string[];
  gallery: (IPopulatedMediaImage | IPopulatedMediaVideo)[];
}

export type IPopulatedArtwork = IArtwork & IArtworkVirtuals;

export interface IPaginatedArtworksMetadata {
  price_bucket: {
    "2000000": number;
    "5000000": number;
    "10000000": number;
    "20000000": number;
    "40000000": number;
    others: number;
  };
  style_and_mood: {
    [key: string]: number;
  };
  room_sizes: {
    [key: string]: number;
  };
}

exports.default = {};
