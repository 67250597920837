import {
  HStack,
  Spacer,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { PurchaseItemRow } from "./components/ExhibitionItemRow";
import { usePurchasesListPage } from "./PurchasesListPage.state";

export const PurchasesListPage = () => {
  const { loading, purchases } = usePurchasesListPage();

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <HStack p={8}>
        <Text fontSize="2xl">Purchases</Text>
        <Spacer />
      </HStack>
      <TableContainer>
        <Table>
          <TableCaption>Purchases</TableCaption>
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Cart Items</Th>
              <Th w="100%">Cart Total</Th>
              <Th>Checkout Date</Th>
              <Th>Payment Date</Th>
              <Th>Status</Th>
              <Th></Th>
            </Tr>
          </Thead>
          <Tbody>
            {purchases.map((purchase) => (
              <PurchaseItemRow key={purchase._id} purchase={purchase} />
            ))}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
};
