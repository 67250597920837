import { Center, Spinner } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { PageViewMode } from "src/constants";
import { ArtistForm } from "./components/ArtistForm/ArtistForm";
import { useArtistDetail } from "./useArtistDetail";

export const EditArtistPage = () => {
  const { artistId } = useParams<{ artistId: string }>();
  const { loading, artist } = useArtistDetail(artistId);

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return <ArtistForm artist={artist} mode={PageViewMode.edit} />;
};
