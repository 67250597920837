import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Spacer,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { LabelledText } from "../../../../../components";
import { useExhibitionDetail } from "../useExhibitionDetail";
import { ExhibitionArtworksTable } from "./components/ExhibitionArtworksTable";
import { ExhibitionDetailPageStyled } from "./ExhibitionDetailPage.styled";

const formatDate = (date: string) => date.split("T")[0];

export const ExhibitionDetailPage = () => {
  const { exhibitionId } = useParams<{ exhibitionId: string }>();
  const { exhibition, loading, onRemoveArtwork } =
    useExhibitionDetail(exhibitionId);

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  const posterImage = exhibition?.poster?.thumbnails.medium;

  return (
    <VStack align="start" p="25px" spacing={4}>
      <HStack w="100%">
        <Text fontSize="2xl">{exhibition.name}</Text>
        <Spacer />

        <Link to={`/dashboard/exhibitions/${exhibition._id}/edit`}>
          <Button size="sm" type="submit" variant="outline">
            Edit
          </Button>
        </Link>
      </HStack>

      <Flex w="100%">
        <ExhibitionDetailPageStyled.PosterWrapper
          mt={2}
          w={posterImage?.width + "px"}
          h={posterImage?.height + "px"}
        >
          <ExhibitionDetailPageStyled.PosterImage
            alt="Exhibition poster"
            src={posterImage?.full_url}
          />
        </ExhibitionDetailPageStyled.PosterWrapper>

        <VStack flex={1} ml={4} spacing={2} align="start" alignItems="stretch">
          <LabelledText label="Name">{exhibition.name}</LabelledText>

          <LabelledText label="Description">
            {exhibition.description ?? "-"}
          </LabelledText>

          <LabelledText label="Venue">{exhibition.venue}</LabelledText>

          <LabelledText label="Entrance Fee">
            {exhibition.entrance_fee ?? "Free"}
          </LabelledText>

          <Flex>
            <Box flex={1}>
              <LabelledText label="Start Date">
                {formatDate(exhibition.start_date)}
              </LabelledText>
            </Box>

            <Box flex={1} ml={2}>
              <LabelledText label="End Date">
                {formatDate(exhibition.end_date)}
              </LabelledText>
            </Box>
          </Flex>
        </VStack>
      </Flex>

      <ExhibitionArtworksTable
        exhibition={exhibition}
        onRemoved={onRemoveArtwork}
        artworks={exhibition.artworks ?? []}
      />
    </VStack>
  );
};
