import { IPopulatedArtwork } from "./IArtwork";
import { IPopulatedMediaFile } from "./IMedia";
import { IWithTimeStamps } from "./IWithTimeStamps";

export interface ICreateArtist {
  name: string;
  slug: string;
  description: string;
  featured: boolean;
  // for home page placement, higher is at top
  ranking: number;
}

export interface IArtist extends ICreateArtist, IWithTimeStamps {
  _id: string;
  featured_artwork_id: string;
  artwork_ids: string[];
}

export interface IArtistVirtuals {
  artworks: IPopulatedArtwork[];
  featured_artwork?: IPopulatedArtwork;
  profile_picture?: IPopulatedMediaFile;
}

export type IPopulatedArtist = IArtist & IArtistVirtuals;

exports.default = {};
