import type { Identifier, XYCoord } from "dnd-core";
import { IPopulatedMedia, SupportedMimeTypes } from "@aatelier/data";
import { DeleteIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";
import { PlayIcon } from "src/components";
import { MediaItemStyled } from "./MediaItem.styled";
import { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

type ArtworkDetailMediaItemProps = {
  index: number;
  showDeleteButton: boolean;
  onDelete: () => void;
  media: IPopulatedMedia;
  moveMedia: (index: number, target: number) => void;
};

interface DragItem {
  index: number;
  id: string;
  type: string;
}

export const ArtworkDetailMediaItem = (props: ArtworkDetailMediaItemProps) => {
  const { media, index, onDelete, showDeleteButton, moveMedia } = props;

  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "media",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleX =
        (hoverBoundingRect.left - hoverBoundingRect.right) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientX =
        (clientOffset as XYCoord).x - hoverBoundingRect.right;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return;
      }

      // Time to actually perform the action
      moveMedia(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "media",
    item: () => {
      return { index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <MediaItemStyled.MediaItemWrapper
      ref={ref}
      $opacity={opacity}
      data-handler-id={handlerId}
    >
      <MediaItemStyled.MediaItemContent>
        {media.mime_type === SupportedMimeTypes.image ? (
          <img
            alt={media.thumbnails.medium?.full_url}
            src={media.thumbnails.medium?.full_url ?? media.file.full_url}
          />
        ) : (
          <>
            <video>
              <source src={media.file.full_url} type="video/mp4" />
            </video>
            <PlayIcon color="white" boxSize={8} />
          </>
        )}
      </MediaItemStyled.MediaItemContent>
      {showDeleteButton && (
        <MediaItemStyled.MediaDeleteButton onClick={onDelete}>
          <IconButton
            size="xs"
            colorScheme="red"
            aria-label="Delete media"
            icon={<DeleteIcon />}
          />
        </MediaItemStyled.MediaDeleteButton>
      )}
    </MediaItemStyled.MediaItemWrapper>
  );
};
