import { Box, Button } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const ExhibitionDetailPageStyled = {
  PosterWrapper: styled(Box)`
    align-items: center;
    background-color: rgba(10, 20, 30, 0.05);
    display: flex;
    justify-content: center;
    padding: 16px;
    position: relative;
  `,
  PosterImage: styled.img`
    object-fit: contain;
  `,
};
