import { Route, Routes } from "react-router-dom";
import { AuthProvider } from "src/providers";
import { DashboardPage } from "./pages/dashboard";
import { LoginPage } from "./pages/LoginPage";

function App() {
  return (
    <AuthProvider>
      <div className="App">
        <Routes>
          <Route index element={<LoginPage />} />
          {DashboardPage.Routes}
        </Routes>
      </div>
    </AuthProvider>
  );
}

export default App;
