import {
  Button,
  Center,
  FormControl,
  FormErrorMessage,
  Spacer,
  FormLabel,
  Input,
  InputGroup,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { useLoginPage } from "src/pages/LoginPage/LoginPage.state";

export const LoginPage = () => {
  const { register, onSubmit, errors } = useLoginPage();

  return (
    <form onSubmit={onSubmit}>
      <Center>
        <VStack spacing={2} w={320}>
          <FormControl isInvalid={!!errors.email}>
            <FormLabel>Email</FormLabel>
            <InputGroup>
              <Input placeholder="john.doe@mail.com" {...register("email")} />
            </InputGroup>
            <FormErrorMessage>{errors.email?.message as any}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={!!errors.password}>
            <FormLabel>Password</FormLabel>
            <InputGroup>
              <Input
                type="password"
                placeholder="Password123"
                {...register("password")}
              />
            </InputGroup>
            <FormErrorMessage>
              {errors.password?.message as any}
            </FormErrorMessage>
          </FormControl>

          <Button type="submit" w="100%" mt={6}>
            Login
          </Button>
        </VStack>
      </Center>
    </form>
  );
};
