import { IPopulatedArtwork, IPopulatedExhibition } from "@aatelier/data";
import {
  Box,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React from "react";
import { ExhibitionArtworksRow } from "./ExhibitionArtworksRow";

type ExhibitionArtworksTableProps = {
  exhibition: IPopulatedExhibition;
  artworks: IPopulatedArtwork[];
  onRemoved: (artworkId: string) => void;
};

export const ExhibitionArtworksTable = ({
  exhibition,
  artworks,
  onRemoved,
}: ExhibitionArtworksTableProps) => {
  return (
    <>
      <Box h="40px" />
      <Box w="100%">
        <Text fontSize="xl">Exhibited Artworks</Text>

        <TableContainer mt={4}>
          <Table size="sm">
            <TableCaption>Artworks</TableCaption>
            <Thead>
              <Tr>
                <Th w="32px">IMG</Th>
                <Th>Title</Th>
                <Th w="32px" />
              </Tr>
            </Thead>
            <Tbody>
              {artworks.map((artwork) => (
                <ExhibitionArtworksRow
                  key={artwork._id}
                  artwork={artwork}
                  onRemoved={onRemoved}
                  exhibition={exhibition}
                />
              ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </>
  );
};
