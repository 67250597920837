import {
  IPopulatedArtist,
  IPopulatedMediaImage,
  SupportedMimeTypes,
} from "@aatelier/data";
import { CheckIcon, TriangleDownIcon, WarningIcon } from "@chakra-ui/icons";
import {
  Box,
  Center,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

type ArtistsListItemProps = {
  artist: IPopulatedArtist;
  isDeleting: boolean;
  onDeletePrompt: () => void;
};

export const ArtistsListItem = (props: ArtistsListItemProps) => {
  const { artist, isDeleting, onDeletePrompt } = props;

  const featuredArtwork = artist.featured_artwork?.gallery?.find(
    (media) => media.mime_type === SupportedMimeTypes.image
  ) as IPopulatedMediaImage;

  const artworkText = (() => {
    if (!artist.artworks.length) return "No artworks";
    if (artist.artworks.length === 1) return "1 artwork";
    return artist.artworks.length + " artworks";
  })();

  return (
    <Tr key={artist._id}>
      <Td>
        {(featuredArtwork && (
          <img
            style={{
              minWidth: 48,
              width: 48,
              height: 48,
              objectFit: "cover",
            }}
            alt={artist.name}
            src={featuredArtwork.thumbnails.medium?.full_url}
          />
        )) || (
          <Center>
            <WarningIcon color="red" mr={2} />
          </Center>
        )}
      </Td>
      <Td>
        <Link to={`/dashboard/artists/${artist._id}`}>
          <Box>
            <Flex alignItems="center">
              <Text as="b">{artist.name}</Text>
            </Flex>
            <Text fontSize="sm">{artworkText}</Text>

            {!featuredArtwork && (
              <Text color="red" fontSize="xs">
                Missing featured artwork
              </Text>
            )}
          </Box>
        </Link>
      </Td>
      <Td>
        <Center>
          {(artist.featured && (
            <Box>
              <CheckIcon />
              <Text size="xs">Rank: {artist.ranking}</Text>
            </Box>
          )) ||
            null}
        </Center>
      </Td>

      <Td>
        {isDeleting ? (
          <Spinner color="red.500" />
        ) : (
          <Menu>
            <MenuButton
              size="sm"
              as={IconButton}
              aria-label="Actions"
              icon={<TriangleDownIcon />}
              variant="outline"
            />
            <MenuList>
              <MenuItem color="red" onClick={onDeletePrompt}>
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Td>
    </Tr>
  );
};
