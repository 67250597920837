import styled from "@emotion/styled";

export const MediaItemStyled = {
  MediaItemWrapper: styled.div<{ $opacity: number }>`
    align-items: center;
    border: 1px solid rgba(10, 20, 30, 0.05);
    cursor: move;
    display: flex;
    height: 128px;
    justify-content: center;
    margin: 0 8px 8px 0;
    overflow: hidden;
    position: relative;
    width: 128px;

    opacity: ${(p) => p.$opacity};
  `,

  MediaItemContent: styled.div`
    max-height: 100%;
    max-width: 100%;
    pointer-events: none;

    > * {
      bottom: 0;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0;
    }
  `,

  MediaDeleteButton: styled.div`
    position: absolute;
    right: 4px;
    top: 4px;
  `,
};
