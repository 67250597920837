export interface IPaginatedResult<T, Metadata = {}> {
  data: T[];
  page?: number;
  total_pages: number;
  next_page?: number | null;
  prev_page?: number | null;
  items_count: number;
  meta: Metadata;
}

exports.default = {};
