import { Center, Spinner } from "@chakra-ui/react";
import React from "react";
import { useParams } from "react-router-dom";
import { PageViewMode } from "src/constants";
import { ArtworkForm } from "./components/ArtworkForm/ArtworkForm";
import { useArtworkDetail } from "./useArtworkDetail";

export const CreateArtworkPage = () => {
  const { artistId } = useParams<{ artistId: string }>();
  const { loading } = useArtworkDetail({});

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <ArtworkForm artistId={artistId!} artwork={{}} mode={PageViewMode.create} />
  );
};
