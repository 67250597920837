import { IMediaFile } from "./IMedia";

export type IImage = {
  url: string;
  blurhash?: string;
  width: number;
  height: number;
  filename: string;
  thumbnails: {
    small: IMediaFile;
    large: IMediaFile;
    full: IMediaFile;
  };
};

exports.default = {};
