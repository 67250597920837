import { IPopulatedExhibition } from "@aatelier/data";
import { useState } from "react";
import { useMount } from "react-use";
import { Config } from "src/config";
import { CachePolicies, useFetch } from "use-http";

type Response = {
  data: IPopulatedExhibition[];
};

export const useExhibitionsListPage = () => {
  const [exhibitions, setExhibitions] = useState<IPopulatedExhibition[]>([]);
  const [idToDelete, setIdToDelete] = useState<string | null>();
  const [loading, $setLoading] = useState(true);

  const { get } = useFetch<Response>(`${Config.app.apiHost}/v1/exhibitions`, {
    loading: true,
    cachePolicy: CachePolicies.NO_CACHE,
  });

  const { delete: deleteRequest, loading: deleteLoading } = useFetch<Response>(
    `${Config.app.apiHost}/v1/exhibitions`,
    {
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );

  useMount(async () => {
    const data = await get();
    if (data?.data) {
      setExhibitions(data.data);
      $setLoading(false);
    }
  });

  const onDelete = async () => {
    await deleteRequest(idToDelete!);
    setExhibitions(
      exhibitions.filter((exhibition) => exhibition._id !== idToDelete)
    );
  };

  return {
    loading,
    setIdToDelete,
    deletingId: deleteLoading ? idToDelete : null,
    onDelete,
    exhibitions,
  };
};
