import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import { RefObject, useRef } from "react";

type AlertProps = {
  isOpen: boolean;
  onClose: () => void;
  title: string;
  body: string;

  ctaLabel: string;
  onCtaClick: () => void;
};

export const SimpleAlert = (props: AlertProps) => {
  const { isOpen, onClose, title, body, ctaLabel, onCtaClick } = props;

  const cancelRef = useRef<any>();

  return (
    <AlertDialog
      isOpen={isOpen}
      onClose={onClose}
      leastDestructiveRef={cancelRef}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold">
            {title}
          </AlertDialogHeader>

          <AlertDialogBody>{body}</AlertDialogBody>

          <AlertDialogFooter>
            <Button ref={cancelRef} onClick={onClose}>
              Cancel
            </Button>
            <Button ml={3} colorScheme="red" onClick={onCtaClick}>
              {ctaLabel}
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
