import {
  CreateArtistRequestSchema,
  ICreateArtist,
  IPopulatedExhibition,
} from "@aatelier/data";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Config } from "src/config";
import { CachePolicies, useFetch } from "use-http";
import { PageViewMode } from "src/constants";
import { ArtistFormProps } from "./ArtistForm.props";

export const useArtistForm = ({ artist, mode }: ArtistFormProps) => {
  const navigate = useNavigate();
  const {
    handleSubmit,
    register,
    watch,
    formState: { errors, dirtyFields, isDirty },
  } = useForm<Partial<ICreateArtist>>({
    resolver: yupResolver(CreateArtistRequestSchema),
    defaultValues: {
      ...artist,
      ranking: artist.ranking ?? 1,
    },
  });
  const { post, patch, loading } = useFetch<IPopulatedExhibition>(
    `${Config.app.apiHost}/v1/artists`,
    {
      cachePolicy: CachePolicies.NO_CACHE,
    }
  );

  const createArtistRequest = async (data: Partial<ICreateArtist>) => {
    return await post(data);
  };

  const updateArtistRequest = async (data: Partial<ICreateArtist>) => {
    const changedValues = (
      Object.keys(dirtyFields) as (keyof ICreateArtist)[]
    ).reduce(
      (all, key) => ({
        ...all,
        [key]: data[key],
      }),
      {}
    );
    return await patch(artist._id, changedValues);
  };

  const onSubmit = handleSubmit(async (data) => {
    let _artist;
    if (mode === PageViewMode.create) {
      _artist = await createArtistRequest(data);
    } else {
      _artist = await updateArtistRequest(data);
    }

    if (_artist?._id) {
      navigate(`/dashboard/artists/${_artist._id}`, {
        replace: true,
      });
    }
  });

  return {
    loading,
    onSubmit,
    register,
    errors,
    isDirty,
    isFeatured: watch("featured"),
  };
};
