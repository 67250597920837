import { IPopulatedPurchase, PurchaseStatus } from "@aatelier/data";
import { Badge, Box, Td, Text, Tr } from "@chakra-ui/react";
import { format } from "date-fns";
import { Link } from "react-router-dom";

const dateFormat = (date: string) => format(new Date(date), "yyyy-MM-dd HH:mm");

const statusToColor: { [key in PurchaseStatus]: string } = {
  created: "yellow",
  paid: "green",
  expired: "red",
};

type PurchaseItemRowProps = {
  purchase: IPopulatedPurchase;
};

export const PurchaseItemRow = ({ purchase }: PurchaseItemRowProps) => {
  return (
    <Tr key={purchase._id}>
      <Td>
        <Link to={`/dashboard/purchases/${purchase._id}`}>
          <Box>
            <Text as="b">{purchase.customer.full_name}</Text>
            <Text fontSize="sm">{purchase.customer.email}</Text>
          </Box>
        </Link>
      </Td>
      <Td>
        <Text>{purchase.artworks.length} artwork(s)</Text>
      </Td>
      <Td>
        <Text>{purchase.purchase_total_locale}</Text>
      </Td>
      <Td>
        <Text>{dateFormat(purchase.created_at)}</Text>
      </Td>
      <Td>
        <Text>{(purchase.paid_at && dateFormat(purchase.paid_at)) || "-"}</Text>
      </Td>
      <Td>
        <Badge colorScheme={statusToColor[purchase.status]}>
          {purchase.status}
        </Badge>
      </Td>
      <Td></Td>
    </Tr>
  );
};
