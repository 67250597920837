import {
  IPopulatedArtwork,
  IPopulatedMediaImage,
  IPopulatedMediaVideo,
} from "@aatelier/data";
import { Spinner } from "@chakra-ui/react";
import React from "react";
import { useMount } from "react-use";
import { Config } from "src/config";
import { UploadQueueItem } from "src/stores/upload_queue.store";
import { CachePolicies, useFetch } from "use-http";
import { MediaUploadQueueStyled } from "./MediaUploadQueue.styled";

type Props = {
  queueItem: UploadQueueItem;
  onUploadCompleted: (
    id: string,
    data: IPopulatedMediaImage | IPopulatedMediaVideo
  ) => void;
};

export const MediaUploadQueue = ({ queueItem, onUploadCompleted }: Props) => {
  const { post: uploadMedia } = useFetch<IPopulatedArtwork>(
    `${Config.app.apiHost}/v1/artworks/${queueItem.artworkId}/media`,
    { cachePolicy: CachePolicies.NO_CACHE }
  );

  useMount(async () => {
    const fd = new FormData();
    fd.append("file", queueItem.file);
    const data = await uploadMedia(fd);
    if (data) {
      const galleryItem = [...data.gallery].reverse()[0] as
        | IPopulatedMediaImage
        | IPopulatedMediaVideo;
      onUploadCompleted(queueItem.id, galleryItem);
    }
  });

  return (
    <MediaUploadQueueStyled.Wrapper
      style={{ backgroundImage: `url(${URL.createObjectURL(queueItem.file)})` }}
    >
      <div
        style={{
          backgroundColor: "white",
          borderRadius: "50%",
          padding: "4px",
          width: "32px",
          height: "32px",
        }}
      >
        <Spinner />
      </div>
    </MediaUploadQueueStyled.Wrapper>
  );
};
