import { IPopulatedMediaImage, SupportedMimeTypes } from "@aatelier/data";
import { TriangleDownIcon } from "@chakra-ui/icons";

import {
  Box,
  Button,
  Center,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Spinner,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { SimpleAlert } from "src/components";
import { useArtworksPage } from "./ArtworksListPage.state";

export const ArtworksListPage = () => {
  const {
    loading,
    artworks,
    onDelete,
    setIdToDelete,
    deletingId,
    fetchNextPage,
    hasNextPage,
  } = useArtworksPage();

  const { isOpen, onOpen, onClose } = useDisclosure();

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <HStack p={8}>
        <Text fontSize="2xl">Artworks</Text>
        <Spacer />
      </HStack>
      <Box marginBottom={4}>
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th w="48px">IMG</Th>
                <Th w="100%">Title</Th>
                <Th>Sort</Th>
                <Th>Price</Th>
                <Th w="48px" />
              </Tr>
            </Thead>
            <Tbody>
              {artworks.map((artwork) => {
                const firstImageMedia = artwork.gallery.find(
                  (media) => media.mime_type === SupportedMimeTypes.image
                ) as IPopulatedMediaImage;

                const thumbnail = firstImageMedia?.thumbnails.medium;
                return (
                  <Tr key={artwork._id}>
                    <Td>
                      {(thumbnail && (
                        <img
                          style={{
                            minWidth: 48,
                            width: 48,
                            height: 48,
                            objectFit: "cover",
                          }}
                          src={thumbnail.full_url}
                          alt={artwork.title}
                        />
                      )) || (
                        <Center>
                          <Text color="red" fontSize="xs">
                            N/A
                          </Text>
                        </Center>
                      )}
                    </Td>

                    <Td>
                      <Link to={`/dashboard/artworks/${artwork._id}`}>
                        <Text as="b">{artwork.title}</Text>
                        <Text fontSize="sm">{artwork.artist.name}</Text>
                      </Link>
                    </Td>

                    <Td>
                      <Text as="b">
                        {artwork.ranking === 0 ? "-" : artwork.ranking}
                      </Text>
                    </Td>

                    <Td>
                      <Box>
                        {(artwork.sold && (
                          <Box>
                            <Text decoration="line-through" color="red">
                              {artwork.price_locale}
                            </Text>
                            <Text fontWeight="bold" fontSize="xs" color="red">
                              Sold
                            </Text>
                          </Box>
                        )) || <Text>{artwork.price_locale}</Text>}
                        {artwork.price_by_request ? (
                          <Text fontWeight="bold" fontSize="xs">
                            Price by Request
                          </Text>
                        ) : null}
                        {(artwork.monthly_rent_price && (
                          <Text fontSize="sm">
                            Rent: {artwork.monthly_rent_price_locale}
                          </Text>
                        )) ||
                          null}
                      </Box>
                    </Td>

                    <Td>
                      {deletingId === artwork._id ? (
                        <Spinner color="red.500" />
                      ) : (
                        <Menu>
                          <MenuButton
                            size="sm"
                            as={IconButton}
                            aria-label="Actions"
                            icon={<TriangleDownIcon />}
                            variant="outline"
                          />
                          <MenuList>
                            <MenuItem
                              color="red"
                              onClick={() => {
                                setIdToDelete(artwork._id);
                                onOpen();
                              }}
                            >
                              Delete
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      )}
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>

      {hasNextPage && (
        <Box
          display="flex"
          justifyContent="end"
          marginBottom={8}
          marginRight={4}
        >
          <Button onClick={fetchNextPage}>Load more</Button>
        </Box>
      )}

      <SimpleAlert
        isOpen={isOpen}
        onClose={onClose}
        title="Delete Artwork?"
        body="Are you sure you want to delete this artwork?"
        ctaLabel="Delete"
        onCtaClick={async () => {
          onClose();
          await onDelete();
        }}
      />
    </>
  );
};
