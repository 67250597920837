import { Config } from "src/config";
import { useAuth } from "src/providers";
import {
  AuthenticationRequestSchema,
  IAccountAuthenticationSchema,
} from "@aatelier/data";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useFetch } from "use-http";

export const useLoginPage = () => {
  const { saveToken } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm<IAccountAuthenticationSchema>({
    resolver: yupResolver(AuthenticationRequestSchema),
  });

  const { post: postVerify, response: verifyResponse } = useFetch<{
    message: string;
    token: string;
  }>(`${Config.app.apiHost}/v1/auth/login`);

  const onSubmit = handleSubmit(async (data) => {
    const response = await postVerify(data);
    if (response.message === "authenticated") {
      saveToken(response.token);
      return window.location.reload();
    }

    if (verifyResponse.status === 401) {
      setError("password", {
        type: "server",
        message: "Invalid email or password",
      });
    }
  });

  return {
    register,
    onSubmit,
    errors,
  };
};
