import styled from "@emotion/styled";

export const ArtworkDetailPageStyled = {
  UploadQueueItem: styled.div`
    align-items: center;
    display: flex;
    height: 128px;
    justify-content: center;
    width: 128px;
  `,

  UploadButton: styled.div`
    align-items: center;
    background-color: rgba(10, 20, 30, 0.05);
    display: flex;
    height: 128px;
    justify-content: center;
    margin: 0 8px 8px 0;
    position: relative;
    width: 128px;
  `,

  UploadInput: styled.input`
    cursor: pointer;
    height: 128px;
    opacity: 0;
    width: 128px;
    position: absolute;
  `,

  QueuePreview: styled.div`
    align-items: end;
    background-color: rgba(10, 20, 30, 0.05);
    background-position: center;
    background-size: cover;
    display: flex;
    height: 128px;
    justify-content: stretch;
    margin: 0 8px 8px 0;
    position: relative;
    width: 128px;
  `,
  QueuePreviewLabel: styled.div`
    background-color: rgba(255, 255, 255, 0.75);
    padding: 0 4px;
    text-align: center;
    width: 100%;
  `,
};
