import * as yup from "yup";
import { IUser } from "../../interfaces";

export type IAccountAuthenticationSchema = {
  email: IUser["email"];
  password: string;
};

export const AuthenticationRequestSchema: yup.SchemaOf<IAccountAuthenticationSchema> =
  yup
    .object()
    .shape({
      email: yup.string().email().required(),
      password: yup.string().min(5).max(20).required(),
    })
    .required();
