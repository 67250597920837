import {
  IExhibition,
  IPopulatedArtwork,
  IPopulatedMediaImage,
  SupportedMimeTypes,
} from "@aatelier/data";
import { TriangleDownIcon } from "@chakra-ui/icons";
import {
  Box,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Td,
  Text,
  Tr,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { useFetch } from "use-http";
import { Config } from "../../../../../../config";

type ExhibitionArtworksRowProps = {
  exhibition: IExhibition;
  artwork: IPopulatedArtwork;
  onRemoved: (artworkId: string) => void;
};

export const ExhibitionArtworksRow = (props: ExhibitionArtworksRowProps) => {
  const { exhibition, artwork, onRemoved } = props;

  const { del, loading } = useFetch(
    `${Config.app.apiHost}/v1/exhibitions/${exhibition._id}/artworks`
  );

  const removeFromExhibition = async () => {
    await del(artwork._id);
    onRemoved(artwork._id);
  };

  const thumbnail = (
    artwork.gallery.find(
      (media) => media.mime_type === SupportedMimeTypes.image
    ) as IPopulatedMediaImage
  )?.thumbnails.small;
  return (
    <Tr key={artwork._id}>
      <Td>
        <Box
          w={"48px"}
          h={"48px"}
          style={{ backgroundColor: "rgba(10, 20, 30, .05)" }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
            src={thumbnail?.full_url}
            alt={artwork.title}
          />
        </Box>
      </Td>

      <Td>
        <Link to={`/dashboard/artworks/${artwork._id}`}>
          <Text as="b">{artwork.title}</Text>
          <Text fontSize="sm">{artwork.artist.name}</Text>
        </Link>
      </Td>
      <Td>
        {loading ? (
          <Spinner color="red.500" />
        ) : (
          <Menu>
            <MenuButton
              size="sm"
              as={IconButton}
              aria-label="Actions"
              icon={<TriangleDownIcon color="gray.400" />}
              variant="outline"
            />
            <MenuList>
              <MenuItem color="red" onClick={removeFromExhibition}>
                Remove from exhibition
              </MenuItem>
            </MenuList>
          </Menu>
        )}
      </Td>
    </Tr>
  );
};
