import * as yup from "yup";
import { ICheckoutRequestDto } from "../../interfaces";

export const CreateCustomerSchema: yup.SchemaOf<
  ICheckoutRequestDto["customer_data"]
> = yup.object({
  full_name: yup.string().max(50).defined(),
  email: yup.string().email().defined(),
  phone: yup.string().max(20).defined(),
  address: yup.string().max(255).defined(),
  province: yup.string().max(255).defined(),
  postal_code: yup
    .string()
    .length(5)
    .matches(/^\d{5}/)
    .defined(),
});

export const CheckoutRequestSchema: yup.SchemaOf<ICheckoutRequestDto> = yup
  .object()
  .shape({
    customer_data: CreateCustomerSchema.defined(),
    artwork_ids: yup.array().of(yup.string()).min(1).required(),
    // voucher_code: yup.string().max(50),
  })
  .required();
