import { IPopulatedMediaImage, PurchaseStatus } from "@aatelier/data";
import {
  Badge,
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Spacer,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { LabelledText } from "../../../../../components";
import { usePurchaseDetail } from "../usePurchaseDetail";

const statusToColor: { [key in PurchaseStatus]: string } = {
  created: "yellow",
  paid: "green",
  expired: "red",
};

export const PurchaseDetailPage = () => {
  const { purchaseId } = useParams<{ purchaseId: string }>();
  const { purchase, loading } = usePurchaseDetail(purchaseId);

  if (loading) {
    return (
      <Center>
        <Spinner />
      </Center>
    );
  }

  return (
    <VStack align="start" p="25px" spacing={4}>
      <HStack w="100%">
        <HStack>
          <Text fontSize="2xl">{purchase.customer.full_name}</Text>

          <Badge colorScheme={statusToColor[purchase.status]}>
            {purchase.status}
          </Badge>
        </HStack>
        <Spacer />

        <Link to={`/dashboard/purchases/${purchase._id}/edit`}>
          <Button size="sm" type="submit" variant="outline">
            Edit
          </Button>
        </Link>
      </HStack>

      <Flex w="100%">
        <VStack flex={1} spacing={2} align="start" alignItems="stretch">
          <LabelledText label="Customer Name">
            {purchase.customer.full_name}
          </LabelledText>

          <LabelledText label="Email">{purchase.customer.email}</LabelledText>

          <LabelledText label="Phone">{purchase.customer.phone}</LabelledText>

          <LabelledText label="Address">
            {purchase.customer.address}, {purchase.customer.province},{" "}
            {purchase.customer.postal_code}
          </LabelledText>

          <LabelledText label="Cart Total">
            {purchase.purchase_total_locale}
          </LabelledText>

          <Text as="b">Artworks purchased</Text>
          <Box>
            {purchase.artworks.map((artwork) => (
              <HStack key={artwork._id} mb={2}>
                <Box mr={2}>
                  <img
                    src={
                      (artwork.gallery[0] as IPopulatedMediaImage).thumbnails
                        .small?.full_url
                    }
                    alt={artwork.title}
                    style={{ width: 64, height: 64, objectFit: "cover" }}
                  />
                </Box>
                <Link to={`/dashboard/artworks/${artwork._id}`}>
                  <Text as="b">{artwork.title}</Text>
                  <Text fontSize="sm">{artwork.artist.name}</Text>
                </Link>

                <Spacer />
                <Text>{artwork.price_locale}</Text>
              </HStack>
            ))}
          </Box>
        </VStack>
      </Flex>
    </VStack>
  );
};
