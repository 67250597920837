declare global {
  interface Window {
    envs: {
      [key: string]: string;
    };
  }
}

/**
 * Helper function that retrieves env variables from the server injected object (in production)
 * or the meta.env (in development).
 * @param envName
 * @param fallback
 */
const fromEnv = (envName: string, fallback: any) => {
  return window.envs?.[envName] || fallback;
};

class AppConfig {
  apiHost: string = fromEnv(
    "REACT_APP_API_HOST",
    process.env.REACT_APP_API_HOST
  );
}

export class Config {
  static app = new AppConfig();
}
