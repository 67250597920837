import { IPopulatedArtist, IPopulatedArtwork } from "@aatelier/data";
import React from "react";
import { useMount } from "react-use";
import { Config } from "src/config";
import { CachePolicies, useFetch } from "use-http";

type ListResponse<T> = {
  data: T[];
};

export const useArtworkDetail = ({ artworkId }: { artworkId?: string }) => {
  const { get } = useFetch<IPopulatedArtwork>(
    `${Config.app.apiHost}/v1/artworks/${artworkId}`,
    { cachePolicy: CachePolicies.NO_CACHE }
  );
  const [artwork, $setArtwork] = React.useState({} as IPopulatedArtwork);
  const [artists, $setArtists] = React.useState<IPopulatedArtist[]>([]);
  const [exhibitions, $setExhibitions] = React.useState<IPopulatedArtwork[]>(
    []
  );
  const [loading, $setLoading] = React.useState(true);

  const { get: getArtists } = useFetch<ListResponse<IPopulatedArtist>>(
    `${Config.app.apiHost}/v1/artists`
  );

  const { get: getExhibitions } = useFetch<ListResponse<IPopulatedArtwork>>(
    `${Config.app.apiHost}/v1/exhibitions`
  );

  useMount(async () => {
    if (!artworkId) {
      const artists = await getArtists();
      const exhibitions = await getExhibitions();
      if (artists && exhibitions) {
        $setArtists(artists.data);
        $setExhibitions(exhibitions.data);
      }
      return $setLoading(false);
    }

    const data = await get();
    if (data) {
      $setArtwork(data);
      $setLoading(false);
    }
  });

  return {
    loading,
    artwork,
    artists,
    exhibitions,
  };
};
