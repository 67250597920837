import { AddIcon } from "@chakra-ui/icons";
import {
  Button,
  HStack,
  Spacer,
  Table,
  TableCaption,
  TableContainer,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import { SimpleAlert } from "../../../../../components";
import { ExhibitionItemRow } from "./components/ExhibitionItemRow";
import { useExhibitionsListPage } from "./ExhibitionsListPage.state";

export const ExhibitionsListPage = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { deletingId, loading, exhibitions, onDelete, setIdToDelete } =
    useExhibitionsListPage();

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <HStack p={8}>
        <Text fontSize="2xl">Exhibitions</Text>
        <Spacer />
        <Link to="/dashboard/exhibitions/new">
          <Button size="sm" leftIcon={<AddIcon />}>
            Add Exhibition
          </Button>
        </Link>
      </HStack>
      <TableContainer>
        <Table>
          <TableCaption>Exhibitions</TableCaption>
          <Thead>
            <Tr>
              <Th w="48px">IMG</Th>
              <Th w="100%">Name</Th>
              <Th>Date</Th>
              <Th w="48px" />
            </Tr>
          </Thead>
          <Tbody>
            {exhibitions.map((exhibition) => (
              <ExhibitionItemRow
                key={exhibition._id}
                exhibition={exhibition}
                isDeleting={deletingId === exhibition._id}
                onPromptDelete={(exhibitionId) => {
                  setIdToDelete(exhibitionId);
                  onOpen();
                }}
              />
            ))}
          </Tbody>
        </Table>
      </TableContainer>

      <SimpleAlert
        isOpen={isOpen}
        onClose={onClose}
        title="Delete Exhibition?"
        body="Are you sure you want to delete this exhibition?"
        ctaLabel="Delete"
        onCtaClick={async () => {
          onClose();
          await onDelete();
        }}
      />
    </>
  );
};
