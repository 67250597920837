import { Config } from "src/config";
import { LocalStorageNames } from "src/constants";
import { IUser } from "@aatelier/data";
import React, { createContext, useContext, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLocalStorage, useMount } from "react-use";
import { useFetch } from "use-http";
import { Provider as HttpProvider } from "use-http";

type IAuthContext = {
  isAuthed?: boolean;
  user?: IUser;
  refreshUser: () => Promise<void>;
  saveToken: (token: string) => void;
  logout: () => Promise<void>;
  logoutLoading: boolean;
};

const AuthContext = createContext({} as IAuthContext);

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }: React.PropsWithChildren) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [accessToken, setAccessToken, clearAccessToken] = useLocalStorage(
    LocalStorageNames.AccessToken
  );
  const [loading, setLoading] = useState(true);

  const { get, response } = useFetch<IUser>(
    `${Config.app.apiHost}/v1/account/me`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const { post: logoutRequest, loading: logoutLoading } = useFetch(
    `${Config.app.apiHost}/v1/auth/logout`,
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

  const fetchUser = async () => {
    await get();
    // redirect into dashboard if not already there
    if (response.status === 200) {
      if (!location.pathname.match(/^\/dashboard/)) {
        navigate("/dashboard/artists");
      }
    } else {
      // clearAccessToken();
    }
  };

  useMount(async () => {
    if (accessToken) {
      await fetchUser();
    } else {
      // redirect to / if no token present or request fails
      if (location.pathname !== "/") {
        navigate("/");
      }
    }
    setTimeout(() => setLoading(false), 300);
  });

  if (loading) {
    return <p>Loading...</p>;
  }

  const onLogout = async () => {
    await logoutRequest();
    clearAccessToken();
    window.location.href = "/";
  };

  const value = {
    isAuthed: response.status === 200,
    user: response.data,
    refreshUser: fetchUser,
    saveToken: setAccessToken,
    logout: onLogout,
    logoutLoading,
  };

  return (
    <HttpProvider
      options={{
        credentials: "include",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      }}
    >
      <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    </HttpProvider>
  );
};
