import { Box, Text } from "@chakra-ui/react";
import React from "react";

type Props = {
  label: string;
} & React.PropsWithChildren;

export const LabelledText = ({ label, children }: Props) => (
  <Box>
    <Text fontSize="xs" as="b">
      {label}
    </Text>
    <Text>{children}</Text>
  </Box>
);
